import React, { useState, useEffect } from 'react';
import './Salesinvoiceprint.css';
import { Button } from '@mui/material';  
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Salesinvoiceprint() {

  const [YourOrderNo, setYourOrderNo] = useState('');
  const [Dated1, setDated1] =useState('');
  const [ParcelSentThrough, setParcelsentthrough] = useState('');
  const [ReceiptNo, setReceiptNo] = useState('');
  const [Dated2, setDated2] = useState('');
  const [Bundles, setBundles] = useState('');
  const [Weight, setWeight] = useState('');
  const [Freight, setFreight] = useState('');
  const [ReceiptSentThrough, setReceiptsentthrough] = useState('');



  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintingModalOpen, setIsPrintingModalOpen] = useState(false); 
  const [isButtonsVisible, setIsButtonsVisible] = useState(true); // State to control button visibility

  const [formData, setFormData] = useState({
    address: 'Phadke Bhavan, Near Hari Mandir, Dudhali, KOLHAPUR: 416 012',
    phone: '(0231) 25 40 211(6 Lines)',
    date: '2024-11-12',
    challanNo: '12345',
    gstNo: '27AABFP6963E1Z4',
    hsnNo: '4901',
  });

  const [inputValues, setInputValues] = useState(
    new Array(9).fill('')  // Create 9 empty values to store in the state
  );

  const [printData, setPrintData] = useState([
    { Srno: 1, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 100, Discount: 50, DiscAmount:50 },
    { Srno: 2, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 100, Discount: 50, DiscAmount:50 },
    { Srno: 3, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 100, Discount: 50, DiscAmount:50 },
    
   
  ]);

  const calculateTotal = () => {
    return printData.reduce((total, item) => {
      return total + item.Copies * item.Amount;
    }, 0);
  };

  const handlePrint = () => {
    setIsPrinting(true);
    setIsButtonsVisible(false); // Hide buttons during the print process

     
    const modalContent = document.querySelector('.salesinvoiceprint-container');
    if (!modalContent) {
      console.error('Printing modal content not found!');
      return;
    }
  
    setTimeout(() => {
      html2canvas(modalContent, {
        scale: 2,
        logging: true,
        useCORS: true,
        dpi: 300,
        scrollX: 0,
        scrollY: -window.scrollY,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        
        const pdf = new jsPDF('p', 'mm', 'A4');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

        const pageHeight = 297;
        const totalHeight = imgHeight + 20;

        if (totalHeight > pageHeight) {
          let remainingHeight = totalHeight - pageHeight;
          let startY = pageHeight + 10;

          while (remainingHeight > 0) {
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 10, startY, imgWidth, imgHeight);
            remainingHeight -= pageHeight;
            startY = 10;
          }
        }

        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'salesinvoice.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsButtonsVisible(true); // Show buttons after the print process is finished
        setIsPrinting(false); // Reset printing state
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF.');
        setIsButtonsVisible(true); // Ensure buttons are visible in case of error
        setIsPrinting(false); // Reset printing state in case of error
      });
    }, 500);
  };
  
  

  const handleInputChange = (index, value) => {
    const updatedValues = [...inputValues];
    updatedValues[index] = value;
    setInputValues(updatedValues);
  };

  return (
    <div className="salesinvoiceprint-container">
      {/* Address and GST details */}
      <div className="salesinvoiceheaderprint-section">
        <div className="salesinvoiceto-section">
        <textarea className='salesinvoiceto-textarea'>To</textarea>
        </div>
        <div className="salesinvoiceaddress-section">
          <p>{formData.address}</p>
          <p>{formData.phone}</p>
        </div>
      </div>

      <div className="salesinvoiceprint-details">
        <h2 style={{color:'#0a60bd'}}>Bill Of Supply</h2>
        <p><b>Date:</b> {formData.date}</p>
        <p><b>Challan No:</b> {formData.challanNo}</p>
        <p><b>GSTIN No:</b> {formData.gstNo}</p>
        <p><b>Books HSN No.</b> {formData.hsnNo}</p>
      </div>


<hr/>
      {/* Input Fields Section */}
      <div className="inputsalesinvoiceprint-fields">
       <div>
        <label className='inputsalesinvoiceprint-label'>
          Your Order No
        </label>
        <div>
          <input
          type='text'
          id='YourOrderNo' name='YourOrderNo' value={YourOrderNo} className='inputsalesinvoiceprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>Dated        </label>
        <div>
          <input
          type='date'
          id='Dated1' name='Dated1' value={Dated1} className='inputsalesinvoiceprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>          Parcel Sent Through
        </label>
        <div>
          <input
          type='text'
          id='ParcelSentThrough' name='ParcelSentThrough' value={ParcelSentThrough}  className='inputsalesinvoiceprint-control'readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>          Receipt No
        </label>
        <div>
          <input
          type='text'
          id='ReceiptNo' name='ReceiptNo' value={ReceiptNo}  className='inputsalesinvoiceprint-control'readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>          Dated
        </label>
        <div>
          <input
          type='date'
          id='Dated2' name='Dated2' value={Dated2}  className='inputsalesinvoiceprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>         Bundles
        </label>
        <div>
          <input
          type='text'
          id='Bundles' name='Bundles' value={Bundles}  className='inputsalesinvoiceprint-control'readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>          Weight Kgs
        </label>
        <div>
          <input
          type='text'
          id='Weight' name='Weight' value={Weight} className='inputsalesinvoiceprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='inputsalesinvoiceprint-label'>          Freight Kgs
        </label>
        <div>
          <input
          type='text'
          id='Freight' name='Freight' value={Freight}  className='inputsalesinvoiceprint-control' readOnly
          />
        </div>
       </div>

       <div>
       <label className='inputsalesinvoiceprint-label'>          Receipt Sent Through
        </label>
        <div>
          <input
          type='text'
          id='ReceiptSentThrough' name='ReceiptSentThrough' value={ReceiptSentThrough}  className='inputsalesinvoiceprint-control' readOnly
          />
        </div>
       </div>
      </div>
<hr style={{color:'1px solid orange'}}/>
      {/* Table Section */}
      <div className="tablesalesinvoiceprint-section">
        <table>
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Title Code No</th>
              <th>Class</th>
              <th>TITLE</th>
              <th>Copies</th>
              <th>Price</th>
              <th>Amount </th>
              <th>Discount</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {printData.map((data, index) => (
              <tr key={index}>
                <td>{data.Srno}</td>
                <td>{data.TitleCodeno}</td>
                <td>{data.Class}</td>
                <td>{data.TITLE}</td>
                <td>{data.Copies}</td>
                <td>{data.Amount}</td>
                <td>{data.Copies * data.Amount}</td> {/* Calculate row total */}
                <td>{data.Discount}</td>
                <td>{data.DiscAmount}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="8" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
              <td style={{ fontWeight: 'bold' }}>{calculateTotal()}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Footer Section */}
      <div className="footersalesinvoiceprint">
        <p>For Phadke Book House</p>
      </div>

      {/* Buttons */}
      {isButtonsVisible && (

      <div className="salesinvoiceprint-btn-container">
      <Button 
  onClick={handlePrint}
  className="salesinvoiceprint-button"
  style={{
    background: "#0a60bd",
    color: "white",
    marginRight: "5px",
  }}
>
  Print
</Button>
<Button 
  className="salesinvoicecancel-button"
  style={{ 
    background: "red",
    color: "white",
  }}
>
  Cancel
</Button>

      </div>
      )}
    </div>
  );
}

export default Salesinvoiceprint;
