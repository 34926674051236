import React, { useState } from 'react';
import './Saleschallanprint.css';
import { Button } from '@mui/material';  
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Saleschallanprint() {
  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintingModalOpen, setIsPrintingModalOpen] = useState(false); 
  const [isButtonsVisible, setIsButtonsVisible] = useState(true); // State to control button visibility

  const [printData, setPrintData] = useState([
    { Srno: 1, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 100, Discount: 50, DiscAmount: 50 },
    { Srno: 2, TitleCodeno: 'A102', Class: 'Class B', TITLE: 'Product 2', Copies: 10, Amount: 200, Discount: 40, DiscAmount: 40 },
    { Srno: 3, TitleCodeno: 'A103', Class: 'Class C', TITLE: 'Product 3', Copies: 15, Amount: 300, Discount: 30, DiscAmount: 30 },
  ]);

  const handlePrint = () => {
    setIsPrinting(true);
    setIsButtonsVisible(false); // Hide buttons during the print process

    

    const modalContent = document.querySelector('.saleschallanprint-container');
    if (!modalContent) {
      console.error('Printing modal content not found!');
      return;
    }

    setTimeout(() => {
      html2canvas(modalContent, {
        scale: 2,
        logging: true,
        useCORS: true,
        dpi: 300,
        scrollX: 0,
        scrollY: -window.scrollY,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        
        const pdf = new jsPDF('p', 'mm', 'A4');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

        const pageHeight = 297;
        const totalHeight = imgHeight + 20;

        if (totalHeight > pageHeight) {
          let remainingHeight = totalHeight - pageHeight;
          let startY = pageHeight + 10;

          while (remainingHeight > 0) {
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 10, startY, imgWidth, imgHeight);
            remainingHeight -= pageHeight;
            startY = 10;
          }
        }

        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'receiptvoucher.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsButtonsVisible(true); // Show buttons after the print process is finished
        setIsPrinting(false); // Reset printing state
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF.');
        setIsButtonsVisible(true); // Ensure buttons are visible in case of error
        setIsPrinting(false); // Reset printing state in case of error
      });
    }, 500);
  };

  return (
    <div className="saleschallanprint-container">
      <textarea className='saleschallanprint-textarea'>To</textarea>

      <div className="saleschallanaboveprint-container">
        <h5>Delivery Challan / Packing Slip</h5>
        <h5>Date:</h5>
        <h5>No:</h5>
      </div>

      <div className="saleschallanprinting-container">
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Code No</th>
              <th>Class</th>
              <th>Title</th>
              <th>Copies</th>
              <th>Rs.</th>
              <th>Ps.</th>
            </tr>
          </thead>
          <tbody>
            {printData.map((item, index) => (
              <tr key={index}>
                <td>{item.Srno}</td>
                <td>{item.TitleCodeno}</td>
                <td>{item.Class}</td>
                <td>{item.TITLE}</td>
                <td>{item.Copies}</td>
                <td>{item.Amount}</td>
                <td>{item.Discount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      <div className="saleschallanprinting-container-signature-section">
      <p >Parcel Through............../Hand Delivery</p>

        <p>Receiver's Signature</p>
        <p>For Phadke Book House</p>
      </div>

      {/* Conditionally render the buttons */}
      {isButtonsVisible && (
        <div className="saleschallan-btn-container">
          <Button onClick={handlePrint} className="saleschallanprint-button"  style={{
    background: "#0a60bd",
    color: "white",
    marginRight: "5px",
  }}>Print</Button>
          <Button onClick={() => setIsPrintingModalOpen(false)} className="saleschallancancel-button" style={{ 
    background: "red",
    color: "white",
  }}>Cancel</Button>
        </div>
      )}
    </div>
  );
}

export default Saleschallanprint;
