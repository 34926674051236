





import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import "./BranchMaster.css";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";

const BranchMaster = () => {
  const [BranchName, setBranchName] = useState("");
 
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [branches, setBranches] = useState([]);
 
  const [errors, setErrors] = useState('');
  const [id, setId] = useState('');

    const [deleteId, setDeleteId] = useState(null);
  
// Confirmation Dialog state
     const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
     const [deleteIndex, setDeleteIndex] = useState(null);
  


     const branchnameRef = useRef(null);
     const saveRef = useRef(null);
     
       const handleKeyDown = (e, nextFieldRef) => {
         if (e.key === 'Enter') {
           e.preventDefault();
           if (nextFieldRef && nextFieldRef.current) {
             nextFieldRef.current.focus();
           }
         }
       };

  useEffect(() => {
   
    fetchBranches();

  }, []);

  

  const fetchBranches = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Branchmasterget.php");
     
      setBranches(response.data);
    } catch (error) {
      toast.error("Error fetching Branches:", error);
    }
  };







  const handleNewClick = () => {
    resetFormFields("");
    setEditingIndex(-1);
    setIsModalOpen(true);
    setIsEditing(false)
  };

  const resetFormFields= ()=> {
    setBranchName('');
  

  }




  const handleEdit = (row) => {
    const branch = branches[row.index];
    setBranchName(branch.BranchName);
    
    setEditingIndex(row.index);
    setIsEditing(true);
    setIsModalOpen(true);
    setId(branch.Id); 

  };




 const handleDelete = (index, Id) => {
      setDeleteIndex(index);
      setDeleteId(Id)
      setIsDeleteDialogOpen(true); // Show confirmation dialog
    };


    const confirmDelete = () => {
       const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        
                        const urlencoded = new URLSearchParams();
                        urlencoded.append("Id", deleteId);
                        
                        const requestOptions = {
                          method: "POST",
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: "follow"
                        };
                        
                        fetch("https://publication.microtechsolutions.co.in/php/Branchmasterdelete.php", requestOptions)
                          .then((response) => response.text())
                          .then((result) => console.log(result))
                          .catch((error) => console.error(error));
                          toast.success('Branch Deleted Successfully')
                          setIsDeleteDialogOpen(false);
                          fetchBranches()
    };

  
    const cancelDelete = () => {
      setIsDeleteDialogOpen(false);
      setDeleteIndex(null);
    };


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!BranchName) {
        formErrors.BranchName = " Branch Name is required.";
        isValid = false;
    }


    
    setErrors(formErrors);
    return isValid;
};


 


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      BranchName: BranchName,
      
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Branchmasterupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Branchmasterpost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Branch updated successfully!');
      } else {
        toast.success('Branch added successfully!');
      }
      setIsModalOpen(false);
      resetFormFields();
      fetchBranches(); // Refresh the list after submit
    } catch (error) {
      // console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };


  

 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "BranchName",
        header: " Branch Name",
        size: 50,
      },
     
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
           <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index, row.original.Id)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [branches]
  );

  const table = useMaterialReactTable({
    columns,
    data: branches,
    muiTableHeadCellProps: {
      style: {
         backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="branch-container">
      <h1>Branch Master</h1>
      <div className="branchtable-container">
        <div className="branchtable1-container">

          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
            fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="branchmastertable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="branch-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen}>
          <div className="branch-modal">
            <h2 
           style={{
            textAlign: "center",
            fontWeight: "620",
            margin: "2px",fontSize:"27px"

            }}>
              {isEditing ? "Edit Branch" : "Add New Branch"}
            </h2>
            <form className="branch-form">
              <div>
                <label className="branch-label"> Branch Name <b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BranchName"
                    name="BranchName"
                    value={BranchName}
                    onChange={(e) => setBranchName(e.target.value)}
                    maxLength={50}
                    ref={branchnameRef} 
                    onKeyDown={(e) => handleKeyDown(e, saveRef)} 
                    className="branch-control"
                    placeholder="Enter Branch Name"
                  />                  
                  <div>
                  {errors.BranchName && <b className="error-text">{errors.BranchName}</b>}
                    </div>

                </div>
              </div>
             
             
            </form>

            <div className="branch-btn-container">
              <Button
                onClick={handleSubmit}
                ref={saveRef} 
                // onKeyDown={(e) => handleKeyDown(e, accgroupnameRef)} 
                style={{
                  background: "#0a60bd",
                  color: "white",
                }}>
         {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

           {/* Confirmation Dialog for Delete */}
                        <Dialog
                          open={isDeleteDialogOpen}
                          onClose={cancelDelete}
                        >
                          <DialogTitle style={{color:'navy', fontWeight:'600'}}>Confirm Deletion</DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this <b style={{color:'red'}}><u>Branch</u></b>?
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={cancelDelete}  style={{
                                background: "red",
                                color: "white",
                                marginRight: "5px", 
                              }}>
                              Cancel
                            </Button>
                            <Button onClick={confirmDelete}  style={{
                                background: "#0a60bd",
                                color: "white",
                                marginRight: "5px", 
                              }}>
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
      </div>
      <ToastContainer />

    </div>
  );
};

export default BranchMaster;
