import React from 'react';
import '../Pages/Home.css';
import home from '../Pages/image.png'

function Home(){
return (
  <div className='home-container'>
  

    <img src={home} alt='book' style={{width: '1000px', height:'500px'}}/>
  </div>
)
}


export default Home;