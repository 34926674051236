import React, { useState, useMemo, useEffect, useSyncExternalStore } from "react";
import './Paymentvoucher.css';
import Select from 'react-select';
import axios from "axios";
import { Button, TextField, Modal } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';
import qs from 'qs';

function Paymentvoucher() {
 

 

    const [VoucherType, setVoucherType] =useState('');
    const [VoucherNo, setVoucherNo] = useState('');
    const [VoucherDate, setVoucherDate] = useState('');
    const [CheckNo, setCheckNo] = useState('');
    const [CheckDate, setCheckDate] = useState('');
    const [Narration, setNarration] = useState('');
//extra post for the detail post


const [CostCenterId, setCostcenterId] = useState('')
const [CheckAmount, setCheckAmount] = useState('');
const [MICRCode, setMICRCode] = useState('');
const [BankName, setBankName] = useState('');
const [BankBranch, setBankBranch] = useState("");


  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [type, setType] = useState('');
  const [voucherdetailId, setVoucherdetailId] = useState('');
const [errors, setErrors] = useState('');
const [isEditing, setIsEditing] = useState(false);
const [voucherheaders, setVoucherheaders] = useState([]);
const [voucherDetails, setVoucherDetails] = useState([]);
const [filteredVouchers, setFilteredVouchers] = useState([]);

const [AccountId, setAccountId] = useState('');
const [Amount, setAmount] = useState(null)
const [DOrC, setDrOrCr] = useState('')


const [costcenterOptions, setCostcenterOptions] = useState([]);
const [accountOptions, setAccountOptions] = useState([]);





const dOrCOptions = [
  { value: 'D', label: 'D' },
  { value: 'C', label: 'C' },
];



  useEffect(()=>{
    fetchVouchers();
    fetchVoucherdetails();
    fetchAccounts();
    fetchCostcenters();
  }, []);

  const fetchVouchers = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Voucherhdget.php?VoucherType=PY");
      setVoucherheaders(response.data);

       
    // Filter vouchers with VoucherType "PY"
    // const filteredVouchers = response.data.filter(voucher => voucher.VoucherType === "PY");
    // setFilteredVouchers(filteredVouchers)
    } catch (error) {
      toast.error("Error fetching Vouchers:", error);
    }
  };

  const [rows, setRows] = useState([
    {
      AccountId: '', // Default value for the first row
    Amount: 0,
    DOrC: '',
    CostCenterId: 0,
    Narration: '',
    CheckNo: 0,
        CheckDate: '',
        CheckAmount: 0,
        MICRCode: '',
        BankName: '',
        BankBranch: ''
    },
  ]);
  

  // Fetch the purchase details
  const fetchVoucherdetails = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Voucherdetailget.php");
      setVoucherDetails(response.data);
    } catch (error) {
      toast.error("Error fetching Voucher details:", error);
    }
  };


  




    
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows]; 
    // Update the value of the current field
    updatedRows[index][field] = value;
  
 
    setRows(updatedRows);
    // calculateTotals();
  };


   
  
  

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        AccountId: '', // Default value for the first row
        Amount: 0,
        DOrC: '',
        CostCenterId: 0,
        Narration: '',
        CheckNo: 0,
            CheckDate: '',
            CheckAmount: 0,
            MICRCode: '',
            BankName: '',
            BankBranch: ''
      },
    ]);

    // calculateTotals();
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    // calculateTotals();
  };





 

 

  const fetchAccounts = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Accountget.php");
      const accountOptions = response.data.map((acc) => ({
        value: acc.Id,
        label: acc.AccountName,
      }));
      setAccountOptions(accountOptions);
    } catch (error) {
      toast.error("Error fetching Accounts:", error);
    }
  };


  const fetchCostcenters = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Costcenterget.php");
      const costcenterOptions = response.data.map((cost) => ({
        value: cost.Id,
        label: cost.CostCenterName,
      }));
      setCostcenterOptions(costcenterOptions);
    } catch (error) {
      toast.error("Error fetching CostCenters:", error);
    }
  };


  
 

  const resetForm = () => {
    setVoucherType('');
    setVoucherNo('');
    setVoucherDate('');
    setCheckNo('');
    setCheckDate('');
    setNarration('');
    setDrOrCr('');
    setAmount('');
    setAccountId('')

    setRows([
      {

        VoucherNo: '',
VoucherDate: '',        
AccountId: '', // Default value for the first row
        Amount: '',
        DOrC: '',
        CostCenterId: '',
        Narration: '',
        CheckNo: '',
            CheckDate: '',
            CheckAmount: '',
            MICRCode: '',
            BankName: '',
            BankBranch: ''
      },
    ]);
};




const handleNewClick = () => {
  resetForm();
  setDrOrCr('C');
  setIsModalOpen(true);
  setIsEditing(false);
  setEditingIndex(-1);
};


  
const handleEdit = (row) => {
  const voucher = voucherheaders[row.index];

  console.log(voucher, 'voucher headers')
  const voucherdetail = voucherDetails.filter(detail => detail.VoucherId === voucher.Id);
  console.log(voucherdetail, 'voucher details')

  const convertDateForInput = (dateStr) => {
    if (typeof dateStr === 'string' && dateStr.includes('-')) {
      const [year, month, day] = dateStr.split(' ')[0].split('-');
      return `${year}-${month}-${day}`;
    } else {
      console.error('Invalid date format:', dateStr);
      return '';
    }
  };

  // Check if voucher and its properties are defined
  if (!voucher || !voucher.VoucherDate || !voucher.CheckDate) {
    console.error('Invalid voucher data:', voucher);
    return; // Exit early if data is invalid
  }

  const voucherdate = convertDateForInput(voucher.VoucherDate.date);
  const checkdate = convertDateForInput(voucher.CheckDate.date);

  setVoucherType(voucher.VoucherType);
  setVoucherNo(voucher.VoucherNo);
  setVoucherDate(voucherdate);
  setCheckNo(voucher.CheckNo);
  setCheckDate(checkdate);


  // Map the details to rows
  const mappedRows = voucherdetail.map(detail => ({
    VoucherId: detail.VoucherId,
    VoucherType: detail.VoucherType,
    VoucherNo: detail.VoucherNo,
    VoucherDate: convertDateForInput(detail.VoucherDate?.date), // Optional chaining
    AccountId: detail.AccountId,
    Amount: detail.Amount,
    DOrC: detail.DOrC,
    CostCenterId: detail.CostCenterId,
    Narration: detail.Narration,
    CheckNo: detail.CheckNo,
    CheckDate: convertDateForInput(detail.CheckDate?.date), // Optional chaining
    CheckAmount: detail.CheckAmount,
    MICRCode: detail.MICRCode,
    BankName: detail.BankName,
    BankBranch: detail.BankBranch,
    Id: detail.Id,
  }));


  // Make sure Amount and AccountId are set
  setAmount(voucherdetail[0]?.Amount || ''); // Default to an empty string if not found
  setAccountId(voucherdetail[0]?.AccountId || '');
  setNarration(voucherdetail[0]?.Narration || '');
  setDrOrCr(voucherdetail[0]?.DOrC || '');



  
  setVoucherType(voucher.VoucherType);
  setVoucherNo(voucher.VoucherNo);
  setVoucherDate(voucherdate);
  setCheckNo(voucher.CheckNo);
  setCheckDate(checkdate);


  setRows(mappedRows);
  setEditingIndex(row.index);
  setIsModalOpen(true);
  setIsEditing(true);
  setId(voucher.Id);
  setVoucherdetailId(voucher.Id)
  fetchVouchers();
  fetchVoucherdetails();
};

  





  


  



  


  const handleDelete = (index) => {
    setVoucherheaders((prevVouchers) =>
      prevVouchers.filter((_, i) => i !== index)
    );
    toast.success('Voucher Deleted Successfully!');
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;
  
    if (!VoucherNo) {
        formErrors.VoucherNo = "Voucher No is required.";
        isValid = false;
    }
    if (!VoucherDate) {
      formErrors.VoucherDate = "Voucher Date is required.";
      isValid = false;
  }
if (!Narration) {
  formErrors.Narration = "Narration is required.";
  isValid = false;
}
    setErrors(formErrors);
    return isValid;
};





// const handleSubmit = async (e) => {
//   e.preventDefault();
//   if (!validateForm()) return;

//   const formattedVoucherDate = moment(VoucherDate).format('YYYY-MM-DD');
//   const formattedCheckDate = moment(VoucherDate).format('YYYY-MM-DD');

//   const voucherData = {
//     Id: isEditing ? id : '',  // Include the Id for updating, null for new records
//     VoucherType: 'PY',
//     VoucherNo: VoucherNo,
//     VoucherDate: formattedVoucherDate,
//     CheckNo: VoucherNo,
//     CheckDate : formattedCheckDate,
//     Narration: Narration,
//   };

//   try {
//     const voucherUrl = isEditing
//       ? "https://publication.microtechsolutions.co.in/php/Voucherhdupdate.php"
//       : "https://publication.microtechsolutions.co.in/php/Voucherhdpost.php";

//     // Submit purchase header data
//     const response = await axios.post(voucherUrl, qs.stringify(voucherData), {
//       headers: { "Content-Type": "application/x-www-form-urlencoded" },
//     });

//     const voucherId = isEditing ? id : parseInt(response.data.Id, 10);




//     for (const [index, row] of rows.entries()) {


//       // if (!row.AccountId || !row.Amount || !row.DOrC || !row.Narration) continue; // Skip empty rows

//     //   if (!row.AccountId || !row.Amount || !row.DOrC || !row.Narration) {
//     //     hasIncompleteRows = true; // Set flag if there are incomplete rows
//     //     continue; // Optional: Skip rows that are not fully filled
//     // }
//             const rowData = {
//               VoucherId: voucherId,
//                 VoucherType: voucherData.VoucherType,
//               SRN: rows.indexOf(row) + 1,
//               // VoucherNo: parseInt(row.VoucherNo, 10),
//               // VoucherDate : (row.VoucherDate),
//               VoucherNo: parseInt(VoucherNo), // Hardcoded VoucherNo
//               VoucherDate: (VoucherDate), // Hardcoded VoucherDate
//               AccountId : parseInt(row.AccountId, 10),
//               Amount : parseFloat(row.Amount),
//               DOrC: index === 0 ? 'C' : row.DOrC, // Set to 'D' for the first row
//               Narration: (row.Narration),

//               CostCenterId: (row.CostCenterId),
//               CheckNo: (row.CheckNo),
//               CheckDate: (row.CheckDate),
//               CheckAmount: (row.CheckAmount),
//               MICRCode: (row.MICRCode),
//               BankName: (row.BankName),
//               BankBranch : (row.BankBranch),
//               Id: row.Id,
//             };
      
      
//             const voucherdetailurl = isEditing && row.Id
//               ? "https://publication.microtechsolutions.co.in/php/Voucherdetailupdate.php"
//               : "https://publication.microtechsolutions.co.in/php/Voucherdetailpost.php";
      
//             await axios.post(voucherdetailurl, qs.stringify(rowData), {
//               headers: {
//                 "Content-Type": "application/x-www-form-urlencoded",
//               },
//             });
//           } 
//     // Fetch updated data
//     fetchVouchers();
//     fetchVoucherdetails(); 
//     setIsModalOpen(false);
//     toast.success(isEditing ? 'Payment Voucher & Payment Voucher Details updated successfully!' : 'Payment Voucher & Payment Voucher Details added successfully!');
//     resetForm(); // Reset the form fields after successful submission
//   } catch (error) {
//     toast.error('Error saving record!');
//   }
// };





const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const formattedVoucherDate = moment(VoucherDate).format('YYYY-MM-DD');
  const formattedCheckDate = moment(VoucherDate).format('YYYY-MM-DD');

  const voucherData = {
    Id: isEditing ? id : '',  // Include the Id for updating, null for new records
    VoucherType: 'PY',
    VoucherNo: VoucherNo,
    VoucherDate: formattedVoucherDate,
    CheckNo: VoucherNo,
    CheckDate : formattedCheckDate,
    Narration: Narration,
  };

  try {
    const voucherUrl = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Voucherhdupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Voucherhdpost.php";

    // Submit purchase header data
    const response = await axios.post(voucherUrl, qs.stringify(voucherData), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    const voucherId = isEditing ? id : parseInt(response.data.Id, 10);




    for (const [index, row] of rows.entries()) {


    
            const rowData = {
              VoucherId: voucherId,
                VoucherType:'PY',
              SRN: rows.indexOf(row) + 1,
              VoucherNo: parseInt(VoucherNo), // Hardcoded VoucherNo
              VoucherDate: (VoucherDate), // Hardcoded VoucherDate
              AccountId : parseInt(row.AccountId, 10),
              Amount : parseFloat(row.Amount),
              DOrC: index === 0 ? 'C' : row.DOrC,
              Narration: (row.Narration),
              CostCenterId: (row.CostCenterId),
              CheckNo: (row.CheckNo),
              CheckDate: (row.CheckDate),
              CheckAmount: (row.CheckAmount),
              MICRCode: (row.MICRCode),
              BankName: (row.BankName),
              BankBranch : (row.BankBranch),
              Id: row.Id,
            };
      
      
            const voucherdetailurl = isEditing && row.Id
              ? "https://publication.microtechsolutions.co.in/php/Voucherdetailupdate.php"
              : "https://publication.microtechsolutions.co.in/php/Voucherdetailpost.php";
      
            await axios.post(voucherdetailurl, qs.stringify(rowData), {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            });
          } 

    // Fetch updated data
// //  Update voucherheaders with the newly created/updated voucher
if (isEditing) {
  setVoucherheaders(prev =>
    prev.map(voucher => (voucher.Id === id ? { ...voucher, ...voucherData } : voucher))
  );
} else {
  setVoucherheaders(prev => [...prev, { ...voucherData, Id: voucherId }]);
}

          fetchVouchers();
    fetchVoucherdetails(); 

    
    setIsModalOpen(false);
    toast.success(isEditing ? 'Payment Voucher & Payment Voucher Details updated successfully!' : 'Payment Voucher & Payment Voucher Details added successfully!');
    resetForm(); // Reset the form fields after successful submission
  } catch (error) {
    toast.error('Error saving record!');
  }
};




















 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },


      {
        accessorKey:'VoucherType',
        header:'Voucher Type',
        size: 50,

      },
    
      {
        accessorKey: "VoucherNo",
        header: "Voucher No",
        size: 50,
      },
      {
        accessorKey: "VoucherDate.date",
        header: "Voucher Date",
        size: 50,
        Cell: ({ cell }) => {
          // Using moment.js to format the date
          const date = moment(cell.getValue()).format('DD-MM-YYYY');
          return <span>{date}</span>;
        },
      },
 

      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>
                          Edit

            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>


          </div>
        ),
      },
    ],
    [voucherheaders]
  );


  const table = useMaterialReactTable({
    columns,
    data: voucherheaders,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

 
  return (
    <div className="paymentvoucher-container">
      <h1>
       Payment Voucher
      </h1>

      <div className="paymentvouchertable-master">
        <div className="paymentvouchertable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
               fontWeight: "700", background:'#0a60bd', width:'15%' }}
            >
            New
          </Button>
          <div className="paymentvouchertable-container">
            <MaterialReactTable table={table}/>
          </div>
        </div>



        {isModalOpen && <div className="paymentvoucher-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="paymentvoucher-modal">
            <h2
                style={{
                  textAlign: "center",
                  fontWeight: "620",
                  margin: "2px",fontSize:"27px"
              }}>
              {editingIndex >= 0 ? "Edit Payment Voucher "  : "Add Payment Voucher"} 
            </h2>
            <form  className="paymentvoucher-form">
              

               

                <div>
                  <label className="paymentvoucher-label">Voucher No <b className="required">*</b></label>
                  <div>
                    <input
                    type="number"
                    id="VoucherNo"
                    name="VoucherNo" 
                    value={VoucherNo}
                    onChange={(e)=> setVoucherNo(e.target.value)}
                    className="paymentvoucher-control"
                    placeholder="Enter Voucher No"
                    />
                  </div>

                  <div>
                          {errors.VoucherNo && <b className="error-text">{errors.VoucherNo}</b>}
                        </div>
                </div>
                <div>
                  <label className="paymentvoucher-label">Voucher Date <b className="required">*</b></label>
                  <div>
                    <input
                    type="date"
                    id="VoucherDate"
                    name="VoucherDate"
                    value={VoucherDate}
                    onChange={(e)=> setVoucherDate(e.target.value)}

                    className="paymentvoucher-control"
                    placeholder="Enter Voucher date"
                    />
                  </div>

                  <div>
                          {errors.VoucherDate && <b className="error-text">{errors.VoucherDate}</b>}
                        </div>
                </div>

                <div>
                <label className="paymentvoucher-label">Select Account <b className="required">*</b></label>
                <div>
                <Select
      id="AccountId"
      name="AccountId"
      value={accountOptions.find((option) => option.value === AccountId)}
      onChange={(option) => {
        setAccountId(option.value);
        if (rows.length > 0) {
          handleInputChange(0, 'AccountId', option.value); // Update first row
        }
      }}
      options={accountOptions}
      styles={{
        control: (base) => ({ ...base, width: "170px", marginTop:'10px' }),
        menu: (base) => ({ ...base, zIndex: 100 }),
      }}
      placeholder="Select Account"
    />

                </div>
              </div>
              
              
              <br/>

   <div>
                <label className="paymentvoucher-label"> Account <b className="required">*</b></label>
                <div>
                <Select
      id="AccountId"
      name="AccountId"
      value={accountOptions.find((option) => option.value === AccountId)}
      onChange={(option) => {
        setAccountId(option.value);
        if (rows.length > 0) {
          handleInputChange(0, 'AccountId', option.value); // Update first row
        }
      }}
      options={accountOptions}
      styles={{
        control: (base) => ({ ...base, width: "170px", marginTop:'10px' }),
        menu: (base) => ({ ...base, zIndex: 100 }),
      }}
      placeholder="Select Account"
    />

                </div>
              </div>


                <div>
  <label className="paymentvoucher-label">Dr/Cr <b className="required">*</b></label>
  <div>
  <Select
      id="DOrC"
      name="DOrC"
      value={dOrCOptions.find((option) => option.value === DOrC)}
      onChange={(option) => {
        setDrOrCr(option.value);
        if (rows.length > 0) {
          handleInputChange(0, 'DOrC', option.value); // Update first row
        }
      }}
      options={dOrCOptions}
      styles={{
        control: (base) => ({ ...base, width: "170px", marginTop:'10px' }),
        menu: (base) => ({ ...base, zIndex: 100 }),
      }}
      placeholder="Select DOrC"
    />

  </div>
</div>



<div>
 <label className="paymentvoucher-label">Narration <b className="required">*</b></label>
<div>
<textarea
      id="Narration"
      name="Narration"
      value={Narration}
      onChange={(e) => {
        setNarration(e.target.value);
        if (rows.length > 0) {
          handleInputChange(0, 'Narration', e.target.value); // Update first row
        }
      }}
      maxLength={1000}
      className="paymentvoucher-control"
      placeholder="Enter Narration"
    />
                  </div>

                  <div>
                          {errors.Narration && <b className="error-text">{errors.Narration}</b>}
                        </div>
                </div>


              <div>
                  <label className="paymentvoucher-label">Amount <b className="required">*</b></label>
                  <div>
                  <input
      type="number"
      id="Amount"
      name="Amount"
      value={Amount}
      onChange={(e) => {
        setAmount(e.target.value);
        if (rows.length > 0) {
          handleInputChange(0, 'Amount', e.target.value); // Update first row
        }
      }}
      className="paymentvoucher-control"
      placeholder="Enter Amount"
    />
                  </div>

                 
                </div>

               
              
              
                
                   
</form>









    <div className="paymentvoucher-table">
  <table>
    <thead>
      <tr>
         <th>Serial No</th>
     

        <th>Account Id <b className="required">*</b></th>
        <th>Dr/Cr <b className="required">*</b></th>
        <th>Narration <b className="required">*</b></th>
        <th>Amount <b className="required">*</b></th>

       

        <th>Cost Center Id <b className="required">*</b></th>
        <th>Check No <b className="required">*</b></th>
        <th>Check Date <b className="required">*</b></th>

        <th>Check Amount <b className="required">*</b></th>
        <th>MICR Code <b className="required">*</b></th>
        <th>Bank Name <b className="required">*</b></th>
        <th>Bank Branch <b className="required">*</b></th>

        <th>Actions</th>
      </tr>
    </thead>

    <tbody>
  {rows.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>

     
  

      <td>
        {index === 0 ? (
          accountOptions.find(option => option.value === row.AccountId)?.label || 'N/A'
        ) : (
          <Select
            value={accountOptions.find(option => option.value === row.AccountId)}
            onChange={(option) => handleInputChange(index, 'AccountId', option.value)}
            options={accountOptions}
            placeholder="Account Id"
            styles={{
              control: (base) => ({ ...base, width: "150px" }),
              menu: (base) => ({ ...base, zIndex: 100 }),
            }}
          />
        )}
      </td>

      <td>
        {index === 0 ? (
          // row.DOrC
          // 'C'
          dOrCOptions.find(option => option.value === row.DOrC)?.label || 'C'

        ) : (
          <Select
            value={dOrCOptions.find(option => option.value === row.DOrC)}
            onChange={(option) => handleInputChange(index, 'DOrC', option.value)}
            options={dOrCOptions}
            placeholder="DOrC"
            styles={{
              control: (base) => ({ ...base, width: "150px" }),
              menu: (base) => ({ ...base, zIndex: 100 }),
            }}
          />
        )}
      </td>

    
      <td>
      {index === 0 ? (
          row.Narration
        ) : (
        <input
          type="text"
          value={row.Narration}
          onChange={(e) => {
            const value = e.target.value;
            // Limit to 500 characters
            if (value.length <= 500) {
                handleInputChange(index, 'Narration', value);
            }
        }}          className="paymentvoucher-control"

          placeholder="Enter Narration"
        />    )}
      </td>


      <td>
        {index === 0 ? (
          row.Amount
        ) : (
          <input
            type="number"
            value={row.Amount}
            onChange={(e) => handleInputChange(index, 'Amount', e.target.value)}
            className="paymentvoucher-control"
            placeholder="Amount"
          />
        )}
      </td>


      <td>
      {/* {index === 0 ? (
          row.CostCenterId
        ) : ( */}
        
        <Select
          value={costcenterOptions.find((option) => option.value === row.CostCenterId)}
          onChange={(option) => handleInputChange(index, 'CostCenterId', option.value)}
          options={costcenterOptions}
          placeholder="CostCenterId"
          styles={{
            control: (base) => ({
              ...base,
              width: "150px",
            }),

            menu: (base) => ({
              ...base,
              zIndex: 100,
            }),
          }}
        />
        {/* )} */}
      </td>
   
      <td>
      {/* {index === 0 ? (
          row.CheckNo
        ) : ( */}
        <input
          type="text"
          value={row.CheckNo}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 15) {
                handleInputChange(index, 'CheckNo', value);
            }
        }}          
            className="paymentvoucher-control"

          placeholder="CheckNo"
        />
        {/* )} */}
      </td>

      <td>
      {/* {index === 0 ? (
          row.CheckDate
        ) : ( */}

         <input
          type="date"
          value={row.CheckDate}
          onChange={(e) => handleInputChange(index, 'CheckDate', e.target.value)}
          placeholder="Check Date"      
               className="paymentvoucher-control"

        />
        {/* )} */}
      </td>

      <td>
      {/* {index === 0 ? (
          row.CheckAmount
        ) : ( */}
        <input
          type="number"
          value={row.CheckAmount}
          onChange={(e) => {
            const value = e.target.value;
            // Validate for Check Amount as well
            const regex = /^\d{0,18}(\.\d{0,2})?$/;

            // Check if the value matches the regex
            if (value === '' || regex.test(value)) {
                handleInputChange(index, 'CheckAmount', value);
            }
        }}          placeholder="Check Amount"           className="paymentvoucher-control"

        />
      {/* )} */}
      </td>
      <td>
      {/* {index === 0 ? (
          row.MICRCode
        ) : ( */}
        <input
          type="text"
          value={row.MICRCode}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 30) {
                handleInputChange(index, 'MICRCode', value);
            }
        }}              placeholder="MICR Code"           className="paymentvoucher-control"

        />
      {/* )} */}
      </td>
      <td>
      {/* {index === 0 ? (
          row.BankName
        ) : ( */}
        <input
          type="text"
          value={row.BankName}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 50) {
                handleInputChange(index, 'BankName', value);
            }
        }}              placeholder="Bank Name"           className="paymentvoucher-control"

        />
      {/* )} */}
      </td>
      <td>
      {/* {index === 0 ? (
          row.BankBranch
        ) : ( */}
        <input
          type="text"
          value={row.BankBranch}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 50) {
                handleInputChange(index, 'BankBranch', value);
            }
        }}            
          placeholder="Bank Branch"      
                 className="paymentvoucher-control"

        />
      {/* )} */}
      </td>
      <td>
<div style={{display:'flex', justifyContent:'space-between'}}>

      <Button
    onClick={handleAddRow}
    style={{ background: "#0a60bd", color: "white", marginRight: "5px" }}>
    Add 
  </Button>
        <Button
          onClick={() => handleDeleteRow(index)}
          style={{ background: "red", color: "white" }}>
          <RiDeleteBin5Line />
        </Button></div>

      </td>
    </tr>
  ))}
</tbody>

  </table>
  
</div> 

<form className="paymentvoucher-form">




                </form>

                <div className="paymentvoucher-btn-container">
                  <Button
                    type="submit" onClick={handleSubmit}
                    style={{
                      background: "#0a60bd",
                      color: "white",
                    }}>
                     {editingIndex >= 0 ? "Update" : "Save"}
                  </Button>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      background: "red",
                      color: "white",
                    }}>
                    Cancel
                  </Button>
                </div>
             
          </div>
        </Modal>
      </div>
      <ToastContainer/>
    </div>
  );
}
export default Paymentvoucher;
