import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import './Salesinvoice.css';
import Select from 'react-select';
import axios from "axios";
import { Button, TextField, Modal } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';
import qs from 'qs';




function Salesinvoice() {
 const [InvoiceNo, setInvoiceNo] = useState('');
 const [InvoiceDate, setInvoiceDate] = useState('');
 const [InvoiceType, setInvoiceType] = useState('');
 const [AccountId, setAccountId] = useState('');
 const [ReceiptNo, setReceiptNo] = useState('');
 const [ReceiptDate, setReceiptDate] = useState('');
 const [Wait, setWait] = useState('');
 const [Bundles, setBundles] = useState('');
 const [Freight, setFreight] = useState('');
 const [DispatchModeId, setDispatchModeId]= useState('');
 const [PaymentMode, setPaymentMode] = useState('');
 const [OrderNo, setOrderNo] = useState('');
 const [OrderDate, setOrderDate] = useState('');
 const [ReceivedDate, setReceivedDate] = useState('');
 const [ReceivedThrough, setReceivedThrough] = useState('');
 const [TotalCopies, setTotalCopies] = useState('');
 const [SubTotal, setSubTotal] = useState('');
 const [Total, setTotal] = useState('');
 const [CanvassorId, setCanvassorId] = useState('');
 const [Remark, setRemark] = useState('');

  const [editingIndex, setEditingIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');

  const [invoicedetailId, setInvoicedetailId] = useState('');
const [errors, setErrors] = useState('');
const [isEditing, setIsEditing] = useState(false);
const [invoiceheaders, setInvoiceheaders] = useState([]);
const [invoicedetails, setInvoiceDetails] = useState([]);

const [isPrinting, setIsPrinting] = useState(false);
const [isPrintingModalOpen, setIsPrintingModalOpen] = useState(false); // For print modal
const [printData, setPrintData] = useState(null); // Data to print


//Dropdown for ID's
const [bookOptions, setBookOptions] = useState([]);
const [accountOptions, setAccountOptions] = useState([]);
const [dispatchmodeOptions, setDispatchModeOptions] = useState([]);
const [canvassorOptions, setCanvassorOptions] = useState([]);

const [rows, setRows] = useState([
  {
    BookId: '', // Default value for the first row
    Copies: 0,
    Rate: 0,
    DiscountPercentage: 0,
    DiscountAmount: 0,
    Amount: 0,
  },
]);



  useEffect(()=>{
    fetchInvoiceheaders();
    fetchInvoicedetails();
    fetchBooks();
    fetchAccounts();
    fetchDispatchModes();
    fetchCanvassors();
  }, []);

  const fetchInvoiceheaders = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Invoiceheaderget.php");
      setInvoiceheaders(response.data);
    } catch (error) {
      toast.error("Error fetching Invoices:", error);
    }
  };

  // Fetch the purchase details
  const fetchInvoicedetails = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Invoicedetailget.php");
      // console.log(response.data, 'response of purchase return details')
      setInvoiceDetails(response.data);
    } catch (error) {
      toast.error("Error fetching Invoice details:", error);
    }
  };


  // Calculation functions
  const calculateTotals = () => {
    let totalCopies = 0;
    let subtotal = 0;
    let total = 0;

    rows.forEach(row => {
      totalCopies += Number(row.Copies) || 0;
      subtotal += Number(row.DiscountAmount) || 0;
      total += Number(row.Amount) || 0;
    });
    setTotalCopies(totalCopies);
    setSubTotal(subtotal);
    setTotal(total);
  };




    
  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    
    // Update the value of the current field
    updatedRows[index][field] = value;
  
    // Calculate the Amount when Copies and Rate are entered
    if (field === 'Copies' || field === 'Rate') {
      const copies = updatedRows[index].Copies || 0;
      const rate = updatedRows[index].Rate || 0;
      updatedRows[index].Amount = copies * rate;
    }
  
    // Calculate the DiscountAmount and FinalAmount when DiscountPercentage is entered
    if (field === 'DiscountPercentage' || field === 'Copies' || field === 'Rate') {
      const discountPercentage = updatedRows[index].DiscountPercentage || 0;
      const amount = updatedRows[index].Amount || 0;
      updatedRows[index].DiscountAmount = (amount * discountPercentage) / 100;
      updatedRows[index].Amount = amount - updatedRows[index].DiscountAmount;
    }
  
    // Update the state with the new row data
    setRows(updatedRows);
    calculateTotals();
  };


   
  
  

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        BookId: "", // This will be empty for new rows
        SerialNo: "",
        Copies: "",
        Rate: "",
        DiscountPercentage: "",
        DiscountAmount: "",
        Amount: "",
      },
    ]);
    calculateTotals();
  };



  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    calculateTotals();
  };





  const fetchBooks = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Bookget.php");
      const bookOptions = response.data.map((book) => ({
        value: book.Id,
        label: book.BookName,
      }));
      setBookOptions(bookOptions);
    } catch (error) {
      toast.error("Error fetching books:", error);
    }
  };

  

  const fetchAccounts = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Accountget.php");
      const accountOptions = response.data.map((acc) => ({
        value: acc.Id,
        label: acc.AccountName,
      }));
      setAccountOptions(accountOptions);
    } catch (error) {
      toast.error("Error fetching Accounts:", error);
    }
  };

  const fetchDispatchModes = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Dispatchmodeget.php");
      const dispatchmodeOptions = response.data.map((disp) => ({
        value: disp.Id,
        label: disp.DispatchModeName,
      }));
      setDispatchModeOptions(dispatchmodeOptions);
    } catch (error) {
      toast.error("Error fetching modes:", error);
    }
  };


  const fetchCanvassors = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/AssignCanvassorget.php");
      const canvassorOptions = response.data.map((can) => ({
        value: can.Id,
        label: can.CanvassorName,
      }));
      setCanvassorOptions(canvassorOptions);
    } catch (error) {
      toast.error("Error fetching canvassors:", error);
    }
  };


  
 

  const resetForm = () => {
    setInvoiceNo('');
    setInvoiceDate('');
    setInvoiceType('');
    setAccountId('');
    setReceiptNo('');
    setReceiptDate('');
    setWait('');
    setBundles('');
    setFreight('');
    setDispatchModeId('');
    setPaymentMode('');
    setOrderNo('');
    setOrderDate('');
    setReceivedDate('');
    setReceivedThrough('');
    setTotalCopies('');
    setSubTotal('');
    setTotal('');
    setCanvassorId('');
    setRemark('');
    setRows([
      {
        BookId: '',
        Copies: 0,
        Rate: 0,
        DiscountPercentage: 0,
        DiscountAmount: 0,
        Amount: 0,
      },
    ]);
};


useEffect(() => {
  calculateTotals();
}, [rows]);


  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setIsEditing(false);
    setEditingIndex(-1);
  };


  

  
  const handleEdit = (row) => {
    const invoiceheader = invoiceheaders[row.index];
    console.log(invoiceheader, 'invoice header')
  
    // Filter purchase details to match the selected PurchaseId
    const invoicedetail = invoicedetails.filter(detail => detail.InvoiceToCanvassorHeaderId === invoiceheader.Id);

    // Map the details to rows
    const mappedRows = invoicedetail.map(detail => ({
      
      InvoiceToCanvassorHeaderId:detail.InvoiceToCanvassorHeaderId,
      BookId: detail.BookId,
      Copies: detail.Copies,
      Rate: detail.Rate,
      DiscountPercentage: detail.DiscountPercentage,
      DiscountAmount: detail.DiscountAmount,
      Amount: detail.Amount,
      Id: detail.Id, // Include the detail Id in the mapped row for tracking
    }));

     // Convert date strings to DD-MM-YYYY format
 const convertDateForInput = (dateStr) => {
  if (typeof dateStr === 'string' && dateStr.includes('-')) {
      const [year, month, day] = dateStr.split(' ')[0].split('-');
      return `${year}-${month}-${day}`;
  } else {
      console.error('Invalid date format:', dateStr);
      return ''; // Return an empty string or handle it as needed
  }
};

    const invoicedate = convertDateForInput(invoiceheader.InvoiceDate.date);
    const receiptdate = convertDateForInput(invoiceheader.ReceiptDate.date);
    const orderdate = convertDateForInput(invoiceheader.OrderDate.date);
    const receiveddate = convertDateForInput(invoiceheader.ReceivedDate.date);


  
    // Set the form fields
    setInvoiceNo(invoiceheader.InvoiceNo);
    setInvoiceDate(invoicedate);
    setInvoiceType(invoiceheader.InvoiceType);
    setAccountId(invoiceheader.AccountId);
    setReceiptNo(invoiceheader.ReceiptNo);
    setReceiptDate(receiptdate);
    setWait(invoiceheader.Wait);
    setBundles(invoiceheader.Bundles);
    setFreight(invoiceheader.Freight);   
    setDispatchModeId(invoiceheader.DispatchModeId);
    setPaymentMode(invoiceheader.PaymentMode);
    setOrderNo(invoiceheader.OrderNo);
    setOrderDate(orderdate);
    setReceivedDate(receiveddate);
    setReceivedThrough(invoiceheader.ReceivedThrough);
    setTotalCopies(invoiceheader.TotalCopies);
    setSubTotal(invoiceheader.SubTotal);
    setTotal(invoiceheader.Total);
    setCanvassorId(invoiceheader.CanvassorId);
    setRemark(invoiceheader.Remark)

  //   console.log(invoiceheader, 'Invoice Header');
  //   console.log(invoicedetail, 'Invoice Detail')
  // console.log(mappedRows, 'mapped rows')
    // Set the rows for the table with all the details
    setRows(mappedRows);
  
    // Set editing state
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
    setId(invoiceheader.Id);
  
    // Determine which specific detail to edit
    const specificDetail = invoicedetail.find(detail => detail.Id === row.original.Id);
    if (specificDetail) {
      setInvoicedetailId(specificDetail.Id); // Set the specific detail Id
    }

    fetchInvoicedetails();
  };
  



  


  



  


  const handleDelete = (index) => {
    setInvoiceheaders((prevInvoices) =>
      prevInvoices.filter((_, i) => i !== index)
    );
    toast.success('Invoice Deleted Successfully!');
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!InvoiceNo) {
        formErrors.InvoiceNo = "Invoice No  is required.";
        isValid = false;
    }
    if (!InvoiceDate) {
        formErrors.InvoiceDate = "Invoice Date is required.";
        isValid = false;
    }

    if (!InvoiceType) {
      formErrors.InvoiceType = "Invoice Type is required.";
      isValid = false;
  }
    if (!AccountId) {
        formErrors.AccountId = "Account Id is required.";
        isValid = false;
    }
    if (!ReceiptNo) {
      formErrors.ReceiptNo = "Receipt No is required.";
      isValid = false;
  }


  if (!ReceiptDate) {
    formErrors.ReceiptDate = "Receipt Date is required.";
    isValid = false;
}
if (!Wait) {
    formErrors.Wait = "Wait is required.";
    isValid = false;
}

if (!Bundles) {
  formErrors.Bundles = "Bundles is required.";
  isValid = false;
}
if (!Freight) {
    formErrors.Freight = "Freight is required.";
    isValid = false;
}
if (!DispatchModeId) {
  formErrors.DispatchModeId = "Dispatch Mode Id is required.";
  isValid = false;
}

if (!PaymentMode) {
  formErrors.PaymentMode = "Payment Mode is required.";
  isValid = false;
}
if (!OrderNo) {
    formErrors.OrderNo = "Order No is required.";
    isValid = false;
}
if (!OrderDate) {
  formErrors.OrderDate = "Order Date is required.";
  isValid = false;
}  
if (!ReceivedDate) {
  formErrors.ReceivedDate = "Received Date is required.";
  isValid = false;
}  

if (!ReceivedThrough) {
  formErrors.ReceivedThrough = "Received Through is required.";
  isValid = false;
}  

if (!TotalCopies) {
  formErrors.TotalCopies = "Total Copies is required.";
  isValid = false;
}  
if (!SubTotal) {
  formErrors.SubTotal = "Sub Total is required.";
  isValid = false;
}  

if (!Total) {
  formErrors.Total = " Total is required.";
  isValid = false;
}  
if (!CanvassorId) {
  formErrors.CanvassorId = "Canvassor Id is required.";
  isValid = false;
}  
if (!Remark) {
  formErrors.Remark = "Remark is required.";
  isValid = false;
}  

    setErrors(formErrors);
    return isValid;
};



const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const formattedInvoiceDate = moment(InvoiceDate).format('YYYY-MM-DD');
  const formattedReceiptDate = moment(ReceiptDate).format('YYYY-MM-DD');
  const formattedOrderDate = moment(OrderDate).format('YYYY-MM-DD');
  const formattedReceivedDate = moment(ReceivedDate).format('YYYY-MM-DD');

  const invoiceheaderData = {
    Id: isEditing ? id : '',  // Include the Id for updating, null for new records
    InvoiceNo: InvoiceNo,
    InvoiceDate: formattedInvoiceDate,
    InvoiceType: InvoiceType,
    AccountId: AccountId,
    ReceiptNo: ReceiptNo, 
    ReceiptDate: formattedReceiptDate, 
    Wait: Wait,
    Bundles: Bundles,
    Freight: Freight, 
    DispatchModeId: DispatchModeId, 
    PaymentMode: PaymentMode,
    OrderNo: OrderNo,
    OrderDate : formattedOrderDate,
    ReceivedDate : formattedReceivedDate,
    ReceivedThrough : ReceivedThrough,
    TotalCopies: TotalCopies, 
    SubTotal : SubTotal,
    Total : Total,
    CanvassorId : CanvassorId,
    Remark: Remark, 
  };

  try {
    const invoiceheaderurl = isEditing
      ? "https://publication.microtechsolutions.co.in/php/Invoiceheaderupdate.php"
      : "https://publication.microtechsolutions.co.in/php/Invoiceheaderpost.php";

    // Submit purchase header data
    const response = await axios.post(invoiceheaderurl, qs.stringify(invoiceheaderData), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    // const purchasereturnId = isEditing ? id : parseInt(response.data.newId, 10);
    const invoicetocanvassorheaderid = isEditing ? id : parseInt(response.data.Id, 10);

    for (const row of rows) {
      const rowData = {
        InvoiceToCanvassorHeaderId: invoicetocanvassorheaderid,
        SRN: rows.indexOf(row) + 1,
        BookId: parseInt(row.BookId, 10),
        Copies: parseInt(row.Copies, 10),
        Rate: parseFloat(row.Rate),
        DiscountPercentage: parseFloat(row.DiscountPercentage),
        DiscountAmount: parseFloat(row.DiscountAmount),
        Amount: parseFloat(row.Amount),
        Id: row.Id,
      };

    
      const invoicedetailurl = isEditing && row.Id
        ? "https://publication.microtechsolutions.co.in/php/Invoicedetailupdate.php"
        : "https://publication.microtechsolutions.co.in/php/Invoicedetailpost.php";

      await axios.post(invoicedetailurl, qs.stringify(rowData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    }

    fetchInvoiceheaders(); 
    fetchInvoicedetails();
    setIsModalOpen(false);
    toast.success(isEditing ? 'Invoice & Invoice Details updated successfully!' : 'Invoice & Invoice Details added successfully!');
    resetForm(); // Reset the form fields after successful submission
  } catch (error) {
    // console.error("Error saving record:", error);
    toast.error('Error saving record!');
  }
};


const navigate = useNavigate();

// Defining this inside the component could cause unnecessary re-renders
const handlePrintButton = useCallback(() => {
  navigate('/transaction/salesinvoice/salesinvoiceprint');
}, [navigate]);  // useCallback ensures the function doesn't get recreated every time





















 

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },

      {
        accessorKey: 'InvoiceNo',
        header: "Invoice No",
        size: 50,
      },

      {
        accessorKey: "InvoiceDate.date",
        header: "Invoice Date",
        size: 50,
        Cell: ({ cell }) => {
          // Using moment.js to format the date
          const date = moment(cell.getValue()).format('DD-MM-YYYY');
          return <span>{date}</span>;
        },
      },
     
      
     
     
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>
                          Edit

            </Button>
            <Button
              onClick={() => handleDelete(row.index)}
              style={{
                background: "red",
                color: "white",fontSize:'22px',  
                marginRight: "5px", 

              }}>
              <RiDeleteBin5Line/>
            </Button>

            <Button
  onClick={handlePrintButton} 
  style={{
                background: "green",
                color: "white",
              }}>
          Print
            </Button>


          </div>
        ),
      },
    ],
    [invoiceheaders]
  );


  const table = useMaterialReactTable({
    columns,
    data: invoiceheaders,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

 
  return (
    <div className="salesinvoice-container">
      <h1>
       Sells Invoice
      </h1>

      <div className="salesinvoicetable-master">
        <div className="salesinvoicetable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
              fontWeight: "700", background:'#0a60bd', width:'15%' }}
            >
            New
          </Button>
          <div className="salesinvoicetable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>



        {isModalOpen && <div className="salesinvoice-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="salesinvoice-modal">
            <h2
              style={{
                textAlign: "center",
                fontWeight: "620",
                margin: "2px",fontSize:"27px"
              }}>
              {editingIndex >= 0 ? "Edit Sells Invoice"  : "Add Sells Invoice"} 
            </h2>
            <form  className="salesinvoice-form">
              

                <div>
                  <label className="salesinvoice-label">Invoice No <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="InvoiceNo"
                     name="InvoiceNo" 
                    value={InvoiceNo}
                    onChange={(e)=> setInvoiceNo(e.target.value)}
                    
                    className="salesinvoice-control"
                    placeholder="Enter Invoice No"
                    />
                  </div>


                  <div>
                          {errors.InvoiceNo && <b className="error-text">{errors.InvoiceNo}</b>}
                  </div>
                </div>
                <div>
                  <label className="salesinvoice-label">Invoice Date <b className="required">*</b></label>
                  <div>
                    <input
                    type="date"
                    id="InvoiceDate"
                    name="InvoiceDate" 
                    value={InvoiceDate}
                    onChange={(e)=> setInvoiceDate(e.target.value)}
                    className="salesinvoice-control"
                    placeholder="Enter Invoice Date"
                    />
                  </div>

                  <div>
                          {errors.InvoiceDate && <b className="error-text">{errors.InvoiceDate}</b>}
               </div>
                </div>

                <div>
                  <label className="salesinvoice-label">Invoice Type <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="InvoiceType"
                    name="InvoiceType" 
                    value={InvoiceType}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 2 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
                          setInvoiceType(inputValue); // Only set the value if it's one letter/digit
                      }
                  }}
                     className="salesinvoice-control"
                    placeholder="Enter Invoice Type"
                    />
                  </div>

                  <div>
                          {errors.InvoiceType && <b className="error-text">{errors.InvoiceType}</b>}
                        </div>
                </div>

                
                
                
               
                <div>
                  <label className="salesinvoice-label"> Account <b className="required">*</b></label>
                  <div>
                  <Select
                   id="PurchaseAccountId"
                   name="PurchaseAccountId"
                   value={accountOptions.find((option) => option.value === AccountId)}
                   onChange={(option) => setAccountId(option.value)}
                   options={accountOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                     }),
                     menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    }),
                   }}
                    placeholder="Select Acc id"
                  />  
                  </div>
                  <div>
                          {errors.AccountId && <b className="error-text">{errors.AccountId}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label">Receipt No <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="ReceiptNo"
                    name="ReceiptNo" 
                    value={ReceiptNo}
                    onChange={(e)=> setReceiptNo(e.target.value)}
                    maxLength={20}
                    className="salesinvoice-control"
                    placeholder="Enter Receipt No"
                    />
                  </div>

                  <div>
                          {errors.ReceiptNo && <b className="error-text">{errors.ReceiptNo}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label">Receipt Date <b className="required">*</b></label>
                  <div>
                    <input
                    type="date"
                    id="ReceiptDate"
                    name="ReceiptDate" 
                    value={ReceiptDate}
                    onChange={(e)=> setReceiptDate(e.target.value)}
                    className="salesinvoice-control"
                    placeholder="Enter Receipt Date"
                    />
                  </div>

                  <div>
                          {errors.ReceiptDate && <b className="error-text">{errors.ReceiptDate}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label">Wait <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="Wait"
                    name="Wait" 
                    value={Wait}
                    onChange={(e) => {
                      const value = e.target.value;
     
                        // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
                        const regex = /^\d{0,18}(\.\d{0,3})?$/;
     
                        // Check if the value matches the regex
                         if (value === '' || regex.test(value)) {
                         setWait(value);
                     }
                       }}                    className="salesinvoice-control"
                    placeholder="Enter Wait"
                    />
                  </div>

                  <div>
                          {errors.Wait && <b className="error-text">{errors.Wait}</b>}
                        </div>
                </div>

                <div>
                  <label className="salesinvoice-label">Bundles <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="Bundles"
                    name="Bundles" 
                    value={Bundles}
                    onChange={(e)=> setBundles(e.target.value)}
                    className="salesinvoice-control"
                    placeholder="Enter Bundles"
                    />
                  </div>

                  <div>
                          {errors.Bundles && <b className="error-text">{errors.Bundles}</b>}
                        </div>
                </div>

                <div>
                  <label className="salesinvoice-label">Freight <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="Freight"
                    name="Freight" 
                    value={Freight}
                    onChange={(e) => {
                      const value = e.target.value;
     
                        // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
                        const regex = /^\d{0,18}(\.\d{0,2})?$/;
     
                        // Check if the value matches the regex
                         if (value === '' || regex.test(value)) {
                         setFreight(value);
                     }
                       }}                    className="salesinvoice-control"
                    placeholder="Enter Freight"
                    />
                  </div>

                  <div>
                          {errors.Bundles && <b className="error-text">{errors.Bundles}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label"> Dispatch Mode <b className="required">*</b></label>
                  <div>
                  <Select
                   id="DispatchModeId"
                   name="DispatchModeId"
                   value={dispatchmodeOptions.find((option) => option.value === DispatchModeId)}
                   onChange={(option) => setDispatchModeId(option.value)}
                   options={dispatchmodeOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                     }),
                     menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    }),
                   }}
                    placeholder="Select mode id"
                  />  
                  </div>
                  <div>
                          {errors.DispatchModeId && <b className="error-text">{errors.DispatchModeId}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label">Payment Mode <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="PaymentMode"
                    name="PaymentMode" 
                    value={PaymentMode}
                    onChange={(e)=> setPaymentMode(e.target.value)}
                    maxLength={1}
                    className="salesinvoice-control"
                    placeholder="Enter Payment Mode"
                    />
                  </div>

                  <div>
                          {errors.PaymentMode && <b className="error-text">{errors.PaymentMode}</b>}
                        </div>
                </div>



              

</form>





    <div className="salesinvoice-table">
  <table>
    <thead>
      <tr>
        <th>Serial No</th>
        <th>Book Name <b className="required">*</b></th>
        <th>Copies <b className="required">*</b></th>
        <th>Rate <b className="required">*</b></th>
        <th>Discount Percentage <b className="required">*</b></th>
        <th>Discount Amount <b className="required">*</b></th>
        <th>Amount <b className="required">*</b></th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
  {rows.map((row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <Select
          value={bookOptions.find((option) => option.value === row.BookId)}
          onChange={(option) => handleInputChange(index, 'BookId', option.value)}
          options={bookOptions}
          placeholder="Select Book"
          styles={{
            control: (base) => ({
              ...base,
              width: "150px",
            }),

            menu: (base) => ({
              ...base,
              zIndex: 100,
            }),
          }}
        />
      </td>
      <td>
        <input
          type="number"
          value={row.Copies}
          onChange={(e) => handleInputChange(index, 'Copies', e.target.value)}
          placeholder="Copies"
          className="salesinvoice-control"

        />
      </td>
      <td>
        <input
          type="number"
          value={row.Rate}
          onChange={(e) => {
            const value = e.target.value;
            // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
            const regex = /^\d{0,18}(\.\d{0,2})?$/;

            // Check if the value matches the regex
            if (value === '' || regex.test(value)) {
                handleInputChange(index, 'Rate', value);
            }
        }}                placeholder="Rate"
          className="salesinvoice-control"

        />
      </td>
      <td>
        <input
          type="number"
          value={row.DiscountPercentage}
          onChange={(e) => {
            const value = e.target.value;
            // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
            const regex = /^\d{0,18}(\.\d{0,2})?$/;

            // Check if the value matches the regex
            if (value === '' || regex.test(value)) {
                handleInputChange(index, 'DiscountPercentage', value);
            }
        }}                placeholder="Discount %"
          className="salesinvoice-control"

        />
      </td>
      <td>
        <input
          type="number"
          value={row.DiscountAmount}
          readOnly
          placeholder="Discount Amount"
          className="salesinvoice-control"

        />
      </td>
      <td>
        <input
          type="number"
          value={row.Amount}
          readOnly
          placeholder="Amount"
          className="salesinvoice-control"

        />


      </td>
      <td>
<div style={{display:'flex', justifyContent:'space-between'}}>

      <Button
    onClick={handleAddRow}
    style={{ background: "#0a60bd", color: "white", marginRight: "5px" }}>
    Add 
  </Button>
        <Button
          onClick={() => handleDeleteRow(index)}
          style={{ background: "red", color: "white" }}>
          <RiDeleteBin5Line />
        </Button></div>

      </td>
    </tr>
  ))}
</tbody>

  </table>
  
</div>
<form className="salesinvoice-form">



<div>
                  <label className="salesinvoice-label">Order No <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="OrderNo"
                    name="OrderNo" 
                    value={OrderNo}
                    onChange={(e)=> setOrderNo(e.target.value)}
                    maxLength={20}
                    className="salesinvoice-control"
                    placeholder="Enter Order No"
                    />
                  </div>

                  <div>
                          {errors.OrderNo && <b className="error-text">{errors.OrderNo}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label">Order Date <b className="required">*</b></label>
                  <div>
                    <input
                    type="date"
                    id="OrderDate"
                    name="OrderDate" 
                    value={OrderDate}
                    onChange={(e)=> setOrderDate(e.target.value)}
                    className="salesinvoice-control"
                    placeholder="Enter Order Date"
                    />
                  </div>

                  <div>
                          {errors.OrderDate && <b className="error-text">{errors.OrderDate}</b>}
                        </div>
                </div>
<div>
                  <label className="salesinvoice-label">Received Date <b className="required">*</b></label>
                  <div>
                    <input
                    type="date"
                    id="ReceivedDate"
                    name="ReceivedDate" 
                    value={ReceivedDate}
                    onChange={(e)=> setReceivedDate(e.target.value)}
                    className="salesinvoice-control"
                    placeholder="Enter Received Date"
                    />
                  </div>

                  <div>
                          {errors.ReceivedDate && <b className="error-text">{errors.ReceivedDate}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label">Received Through <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="ReceivedThrough"
                    name="ReceivedThrough" 
                    value={ReceivedThrough}
                    onChange={(e)=> setReceivedThrough(e.target.value)}
                    maxLength={50}
                    className="salesinvoice-control"
                    placeholder="Enter Received Through"
                    />
                  </div>

                  <div>
                          {errors.ReceivedThrough && <b className="error-text">{errors.ReceivedThrough}</b>}
                        </div>
                </div>
                <div>
                  <label className="bookpurchase-label">Total Copies <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="TotalCopies"
                     name="TotalCopies"
                      value={TotalCopies}
                      onChange={(e)=> setTotalCopies(e.target.value)}
                    className="bookpurchase-control"
                    placeholder="Enter Total Copies"
                    />
                  </div>
                  <div>
                          {errors.TotalCopies && <b className="error-text">{errors.TotalCopies}</b>}
                        </div>
                </div>



                <div>
                  <label className="salesinvoice-label">Sub Total <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="SubTotal"
                     name="SubTotal"
                      value={SubTotal}                  
                      onChange={(e) => {
                        const value = e.target.value;
       
                          // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
                          const regex = /^\d{0,18}(\.\d{0,2})?$/;
       
                          // Check if the value matches the regex
                           if (value === '' || regex.test(value)) {
                           setSubTotal(value);
                       }
                         }}
                    className="salesinvoice-control"
                    placeholder="Enter Sub Total"
                    />
                  </div>

                  <div>
                          {errors.SubTotal && <b className="error-text">{errors.SubTotal}</b>}
                        </div>
                </div>

              
                


               

                <div>
                  <label className="salesinvoice-label">Total <b className="required">*</b></label>
                  <div>
                    <input
                    type="text"
                    id="Total"
                     name="Total"
                      value={Total}                  
                      onChange={(e) => {
                        const value = e.target.value;
       
                          // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
                          const regex = /^\d{0,18}(\.\d{0,2})?$/;
       
                          // Check if the value matches the regex
                           if (value === '' || regex.test(value)) {
                           setTotal(value);
                       }
                         }}
                    className="bookpurchase-control"
                    placeholder="Enter Total"
                    />
                  </div>

                  <div>
                          {errors.Total && <b className="error-text">{errors.Total}</b>}
                        </div>
                </div>


                <div>
                  <label className="salesinvoice-label"> Canvassor <b className="required">*</b></label>
                  <div>
                  <Select
                   id="CanvassorId"
                   name="CanvassorId"
                   value={canvassorOptions.find((option) => option.value === CanvassorId)}
                   onChange={(option) => setCanvassorId(option.value)}
                   options={canvassorOptions} 
                   styles={{
                     control: (base) => ({
                       ...base,
                       width: "170px",
                              marginTop: "10px",
                              borderRadius: "4px",
                              border: "1px solid rgb(223, 222, 222)",
                               marginBottom: '5px'
                     }),
                     menu: (base) => ({
                      ...base,
                      zIndex: 100,
                    }),
                   }}
                    placeholder="Select can id"
                  />  
                  </div>
                  <div>
                          {errors.CanvassorId && <b className="error-text">{errors.CanvassorId}</b>}
                        </div>
                </div>


               

                <div>
                  <label className="bookpurchase-label">Remark <b className="required">*</b></label>
                <div>
                    <input
                    type="text"
                    id="Remark"
                     name="Remark"
                      value={Remark}
                      onChange={(e)=> setRemark(e.target.value)}
                      maxLength={200}
                    className="bookpurchase-control"
                    placeholder="Enter Remark"
                    />
                  
                </div>

                <div>
                          {errors.Remark && <b className="error-text">{errors.Remark}</b>}
                        </div>

                </div>


                </form>
                <div className="bookpurchase-btn-container">
                  <Button
                    type="submit" onClick={handleSubmit}
                    style={{
                      background: "#0a60bd",
                      color: "white",
                    }}>
                     {editingIndex >= 0 ? "Update" : "Save"}
                  </Button>
                  <Button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      background: "red",
                      color: "white",
                    }}>
                    Cancel
                  </Button>
                </div>
             
          </div>
        </Modal>

      </div>
      <ToastContainer/>
    </div>
  );
}
export default Salesinvoice;
