import React from 'react'

function RoyaltyVoucher() {
  return (
    <div>
      RoyaltyVoucher
      RoyaltyVoucher
    </div>
  )
}

export default RoyaltyVoucher
