import React, { useState, useMemo, useEffect, useRef } from "react";
import "./Book.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";
import {CiEdit   } from "react-icons/ci";
import moment from 'moment';
import qs from 'qs';
import VirtualKeyboard from "./VirtualKeyboard";
import { CircularProgress } from '@mui/material';



function Book() {
  const [BookCode, setBookCode] = useState("");
  const [BookName, setBookName] = useState("");
  const [BookNameMarathi, setBookNameMarathi] = useState('');

  const [BookGroupId, setBookGroupId] = useState("");
  const [bookgroupOptions, setBookgroupOptions] = useState([]);

  const [BookStandardId, setBookStandardId] = useState("");
  const [bookstandardOptions, setBookstandardOptions] = useState([]);

  const [PublicationId, setPublicationId] = useState("");
  const [publicationOptions, setPublicationOptions] = useState([]);

  const [UniversityId, setUniversityId] = useState("");
  const [universityOptions, setUniversityOptions] = useState([]);

  const [BookMediumId, setBookMediumId] = useState("");
  const [bookmediumOptions, setBookmediumOptions] = useState([]);

  const [CurrentEdition, setCurrentEdition] = useState("");
  const [BookRate, setBookRate] = useState("");
  const [BookPages, setBookPages] = useState("");
  const [BookForms, setBookForms] = useState("");
  const [FillingDate, setFilingDate] = useState("");
  const [TitlePages, setTitlePages] = useState('');
  const [TitlePressId, setTitlePressId] = useState("");
  const [titlepressOptions, setTitlepressOptions] = useState([]);

  const [PaperSizeId, setPaperSizeId] = useState("");
  const [papersizeOptions, setPapersizeOptions] = useState([]);

  const [PressId, setPressId] = useState("");
  const [pressOptions, setPressOptions] = useState([]);

  const [Status, setStatus] = useState("");
  const [OpeningStock, setOpeningStock] = useState("");


  const [ReprintFlag, setReprintFlag] = useState('');
  const [PrintOrder, setPrintOrder] = useState('');
  const [BookId, setBookId] = useState('');
  const [CreationDate, setCreationDate] = useState('');
  const [CurrentEditionDate, setCurrentEditionDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [books, setBooks] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
const [errors, setErrors] = useState('')
const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
// Confirmation Dialog state
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
   const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(true);







//Enter button hit go to next input value in Address side

  const bookcodeRef = useRef(null);
  
  const booknameRef = useRef(null);
  const booknamemarathiRef = useRef(null);
  const bookgroupIdRef = useRef(null);

  const bookstandardidRef = useRef(null);
  const publicationRef = useRef(null);
  const universityRef = useRef(null);
  const bookmediumRef = useRef(null);
  const currenteditionRef = useRef(null);
  const bookrateRef = useRef(null);
  const bookpagesRef = useRef(null);
  const bookformsRef = useRef(null);
  const fillingdateRef = useRef(null);
  const titlepagesRef = useRef(null);
  const titlepressRef = useRef(null);
  const papersizeRef = useRef(null);
  const pressRef = useRef(null);
  const statusRef = useRef(null);
  const openingstockRef = useRef(null);
  const reprintflagRef = useRef(null);
  const printorderRef = useRef(null);
  const bookidRef = useRef(null);
  const creationdateRef = useRef(null);

  const curreditiondateRef = useRef(null);
const saveRef = useRef(null);

  const handleKeyDown = (e, nextFieldRef) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (nextFieldRef && nextFieldRef.current) {
        nextFieldRef.current.focus();
      }
    }
  };



const handleFocus = () => {
  setIsKeyboardVisible(true);  // Show the keyboard when input is focused
};

const handleBlur = () => {
  setIsKeyboardVisible(false);  // Hide the keyboard when input is blurred
};



const handleKeyPress = (key) => {
  setBookNameMarathi((prevValue) => prevValue + key);
};





  useEffect(() => {
    fetchAllBooks();
    fetchBookgroups();
    fetchStandards();
    fetchPublications();
    fetchUniversities();
    fetchmediums();
    fetchTitlepresses();
     fetchPapersize(); 
     fetchPresses();

  }, []);

  const fetchAllBooks = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Bookget.php");
      setBooks(response.data);
      setLoading(false);  // Set loading to false after data is fetched

    } catch (error) {
      toast.error("Error fetching books:", error);
      setLoading(false);  // Set loading to false in case of an error

    }
  };


  const fetchBookgroups = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/BookGroupget.php");

      const bookgroupOptions = response.data.map((group) => ({
        value: group.Id,
        label: group.BookGroupName,
      }));
      setBookgroupOptions(bookgroupOptions);
    } catch (error) {
      toast.error("Error fetching book groups:", error);
    }
  };

  const fetchStandards = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Standardget.php");
      const bookstandardOptions = response.data.map((std) => ({
        value: std.Id,
        label: std.StandardName,
      }));
      setBookstandardOptions(bookstandardOptions);
    } catch (error) {
      console.error("Error fetching  standards:", error);
    }
  };

  const fetchPublications = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Publicationget.php");
      const publicationOptions = response.data.map((pub) => ({
        value: pub.Id,
        label: pub.PublicationName,
      }));
      setPublicationOptions(publicationOptions);
    } catch (error) {
      toast.error("Error fetching  publications:", error);
    }
  };
  const fetchUniversities = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Universityget.php");
      const universityOptions = response.data.map((uni) => ({
        value: uni.Id,
        label: uni.UniversityName,
      }));
      setUniversityOptions(universityOptions);
    } catch (error) {
      toast.error("Error fetching  universities:", error);
    }
  };
  const fetchmediums = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/BookMediumget.php");
      const bookmediumOptions = response.data.map((med) => ({
        value: med.Id,
        label: med.BookMediumName,
      }));
      setBookmediumOptions(bookmediumOptions);
    } catch (error) {
      toast.error("Error fetching  mediums:", error);
    }
  };
  const fetchTitlepresses = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/TitlePressget.php");
      const titlepressOptions = response.data.map((title) => ({
        value: title.Id,
        label: title.TitlePressName,
      }));
      setTitlepressOptions(titlepressOptions);
    } catch (error) {
      toast.error("Error fetching  title press:", error);
    }
  };
  const fetchPapersize = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/PaperSizeget.php");
      const papersizeOptions = response.data.map((paper) => ({
        value: paper.Id,
        label: paper.PaperSizeName,
      }));
      setPapersizeOptions(papersizeOptions);
    } catch (error) {
      toast.error("Error fetching  papersize:", error);
    }
  };
  const fetchPresses = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/PressMasterget.php");
      const pressOptions = response.data.map((press) => ({
        value: press.Id,
        label: press.PressName,
      }));
      setPressOptions(pressOptions);
    } catch (error) {
      toast.error("Error fetching  presses:", error);
    }
  };

  
  

 

  const resetForm = () => {
    setBookCode("");
    setBookName("");
    setBookNameMarathi("")
    setBookGroupId("");
    setBookStandardId("");
    setPublicationId("");
    setUniversityId("");
    setBookMediumId("");
    setCurrentEdition("");
    setBookRate("");
    setBookPages("");
    setBookForms("");
    setFilingDate("");
    setTitlePages('');
    setTitlePressId("");
    setPaperSizeId("");
    setPressId("");
    setStatus("");
    setOpeningStock("");
    setReprintFlag('');
    setPrintOrder('');
    setBookId('');
    setCreationDate('');
    setCurrentEditionDate('');
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setIsEditing(false);
    setEditingIndex(-1);
  };



  const handleEdit = (row) => {
    const convertDateForInput = (dateStr) => {
        if (typeof dateStr === 'string' && dateStr.includes('-')) {
            const [year, month, day] = dateStr.split(' ')[0].split('-');
            return `${year}-${month}-${day}`;
        } else {
            toast.error('Invalid date format:', dateStr);
            return ''; 
        }
    };
    const book = books[row.index];
    console.log(book, 'selected row of book')
    setBookCode(book.BookCode);
    setBookName(book.BookName);

    // const decodedText = decodeURIComponent(escape(book.BookNameMarathi));
    // setBookNameMarathi(decodedText);

    setBookNameMarathi(book.BookNameMarathi)
    setBookGroupId(book.BookGroupId);
    setBookStandardId(book.BookStandardId);
    setPublicationId(book.PublicationId);
    setUniversityId(book.UniversityId);
    setBookMediumId(book.BookMediumId);
    setCurrentEdition(book.CurrentEdition);
    setBookRate(book.BookRate);
    setBookPages(book.BookPages);
    setBookForms(book.BookForms);
    setFilingDate(convertDateForInput(book.FillingDate));
    setTitlePages(book.TitlePages);
    setTitlePressId(book.TitlePressId);
    setPaperSizeId(book.PaperSizeId);
    setPressId(book.PressId);
    setStatus(book.Status);
    setOpeningStock(book.OpeningStock);
    setReprintFlag(book.ReprintFlag);
    setPrintOrder(book.PrintOrder);
    setBookId(book.BookId);
    setCreationDate(convertDateForInput(book.CreationDate));
    setCurrentEditionDate(convertDateForInput(book.CurrentEditionDate));
    setEditingIndex(row.index);
    setIsModalOpen(true);
    setIsEditing(true);
    setId(book.Id);
  };

 


  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!BookCode) {
      formErrors.BookCode = "Book Code is required.";
      isValid = false;
  } 

    if (!BookName) {
        formErrors.BookName = "Book Name is required.";
        isValid = false;
    }

    

    if (!BookNameMarathi) {
        formErrors.BookNameMarathi = "Book Name Marathi is required.";
        isValid = false;
    }

    if (!BookGroupId) {
        formErrors.BookGroupId = "Book group id is required.";
        isValid = false;
    }

    if (!BookStandardId) {
        formErrors.BookStandardId = "Book standard id is required.";
        isValid = false;
    }

    if (!PublicationId) {
      formErrors.PublicationId = "Publication id is required.";
      isValid = false;
  }

  if (!UniversityId) {
    formErrors.UniversityId = "University id is required.";
    isValid = false;
}

    if (!BookMediumId) {
        formErrors.BookMediumId = "Book Medium Id is required.";
        isValid = false;
    }

    // if (!CurrentEdition) {
    //     formErrors.CurrentEdition = "Current Edition is required.";
    //     isValid = false;
    // } 

    if (!BookRate) {
      formErrors.BookRate = "Book rate is required.";
      isValid = false;
  } 
//   if (!BookPages) {
// formErrors.BookPages = "Book pages are  required.";
//     isValid = false;
// } 
// if (!BookForms) {
//   formErrors.BookForms = "Book forms are required.";
//   isValid = false;
// }

// if (!TitlePages) {
//   formErrors.TitlePages = "Title pages are required.";
//   isValid = false;
// }

if (!FillingDate) {
  formErrors.FillingDate = "Filing date is required.";
  isValid = false;
}

if (!TitlePressId) {
  formErrors.TitlePressId = "Title press id is required.";
  isValid = false;
}

if (!PaperSizeId) {
  formErrors.PaperSizeId = "Paper size id is required.";
  isValid = false;
}

if (!PressId) {
  formErrors.PressId = "Press id is required.";
  isValid = false;
}

    

    // if (!Status) {
    //     formErrors.Status = "Status is required.";
    //     isValid = false;
    // }

    if (!OpeningStock) {
      formErrors.OpeningStock = "Opening stock is required.";
      isValid = false;
  }

  // if (!ReprintFlag) {
  //   formErrors.ReprintFlag = "Reprint Flag is required.";
  //   isValid = false;
  // }
  
      
  
      // if (!PrintOrder) {
      //     formErrors.PrintOrder = "Print Order is required.";
      //     isValid = false;
      // }
  
      if (!BookId) {
        formErrors.BookId = "Book Id is required.";
        isValid = false;
    }

    if (!CreationDate) {
      formErrors.CreationDate = "Creation Date is required.";
      isValid = false;
  }

  if (!CurrentEditionDate) {
    formErrors.CurrentEditionDate = "Current Edition Date is required.";
    isValid = false;
}

    setErrors(formErrors);
    return isValid;
};







// const fetchBookcodedata = async (BookCode) => {
//     try {
//       const cleanedBookCode = BookCode.trim();  // Normalize AccountCode
//       console.log('Fetching data for BookCode:', cleanedBookCode);
  
//       const response = await fetch(`https://publication.microtechsolutions.co.in/php/Bookcodeget.php?BookCode=${cleanedBookCode}`);
      
//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }
  
//       const bookData = await response.json();
//       console.log('API response:', bookData);  // Log the entire API response
  
//       if (Array.isArray(bookData) && bookData.length > 0) {
//         // Compare AccountCode as a number
//         const data = bookData.find(item => {
//           console.log('Item Bookcode:', item.BookCode);  // Log each AccountCode for debugging
//           return item.BookCode === parseInt(cleanedBookCode, 10);  // Convert cleanedAccountCode to number for comparison
//         });
  
//         if (data) {
//           console.log('Found data:', data);  // Log the matching data

//           const convertDateForInput = (dateStr) => {
//             if (typeof dateStr === 'string' && dateStr.includes('-')) {
//                 const [year, month, day] = dateStr.split(' ')[0].split('-');
//                 return `${year}-${month}-${day}`;
//             } else {
//                 toast.error('Invalid date format:', dateStr);
//                 return ''; 
//             }
//         };
//           setBookName(data.BookName || '');
//           setBookNameMarathi(data.BookNameMarathi || '')
//     setBookGroupId(data.BookGroupId || '');
//     setBookStandardId(data.BookStandardId || '');
//     setPublicationId(data.PublicationId || '');
//     setUniversityId(data.UniversityId || '');
//     setBookMediumId(data.BookMediumId || '');
//     setCurrentEdition(data.CurrentEdition || '');
//     setBookRate(data.BookRate || '');
//     setBookPages(data.BookPages || '');
//     setBookForms(data.BookForms || '');
//     setFilingDate(convertDateForInput(data.FillingDate) || '');
//     setTitlePages(data.TitlePages || '');
//     setTitlePressId(data.TitlePressId || '');
//     setPaperSizeId(data.PaperSizeId || '');
//     setPressId(data.PressId || '');
//     setStatus(data.Status || '');
//     setOpeningStock(data.OpeningStock || '');
//     setReprintFlag(data.ReprintFlag || '');
//     setPrintOrder(data.PrintOrder || '');
//     setBookId(data.BookId || '');
//     setCreationDate(convertDateForInput(data.CreationDate) || '');
//     setCurrentEditionDate(convertDateForInput(data.CurrentEditionDate) || '');
//         } else {
//           console.log('No matching data found for Book code:', cleanedBookCode);
//           // toast.error('No data found for the provided Book Code');
//         }
//       } else {
//         // toast.error('No data returned from the API');
//       }
//     } catch (error) {
//       console.error('Error fetching book data:', error);
//       // toast.error('Error fetching book data');
//     }
//   };
  
  
  
  
  


// Handle Account Code input change
const handleBookcodechange = (e) => {
  const value = e.target.value;
  setBookCode(value);

  // Fetch data when Account Code is changed
  if (value.length >= 0 || 2 || 3) { // Optional: Fetch after 3 characters to avoid excessive requests
    fetchBookcodedata(value);
  }

};

const fetchBookcodedata = async (BookCode) => {
  try {
    const cleanedBookCode = BookCode.trim();  // Normalize BookCode
    console.log('Fetching data for BookCode:', cleanedBookCode);

    const response = await fetch(`https://publication.microtechsolutions.co.in/php/Bookcodeget.php?BookCode=${cleanedBookCode}`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const bookData = await response.json();
    console.log('API response:', bookData);  // Log the entire API response

    if (Array.isArray(bookData) && bookData.length > 0) {
      // Compare BookCode as a string
      const data = bookData.find(item => {
        console.log('Item Bookcode:', item.BookCode);  // Log each BookCode for debugging
        return item.BookCode.toString() === cleanedBookCode;  // Compare as strings
      });

      if (data) {
        console.log('Found data:', data);  // Log the matching data

        const convertDateForInput = (dateStr) => {
          if (typeof dateStr === 'string') {
            // Try to match 'YYYY-MM-DD' format first
            if (dateStr.includes('-')) {
              const [year, month, day] = dateStr.split(' ')[0].split('-');
              return `${year}-${month}-${day}`;
            }
            
            // Try to match 'MM/DD/YYYY' format
            if (dateStr.includes('/')) {
              const [month, day, year] = dateStr.split(' ')[0].split('/');
              return `${year}-${month}-${day}`;
            }

            // Handle invalid formats
            toast.error('Invalid date format:', dateStr);
            return '';
          } else {
            return ''; // Handle non-string date values
          }
        };

        setBookName(data.BookName || '');
        setBookNameMarathi(data.BookNameMarathi || '');
        setBookGroupId(data.BookGroupId || '');
        setBookStandardId(data.BookStandardId || '');
        setPublicationId(data.PublicationId || '');
        setUniversityId(data.UniversityId || '');
        setBookMediumId(data.BookMediumId || '');
        setCurrentEdition(data.CurrentEdition || '');
        setBookRate(data.BookRate || '');
        setBookPages(data.BookPages || '');
        setBookForms(data.BookForms || '');
        setFilingDate(convertDateForInput(data.FillingDate) || '');
        setTitlePages(data.TitlePages || '');
        setTitlePressId(data.TitlePressId || '');
        setPaperSizeId(data.PaperSizeId || '');
        setPressId(data.PressId || '');
        setStatus(data.Status || '');
        setOpeningStock(data.OpeningStock || '');
        setReprintFlag(data.ReprintFlag || '');
        setPrintOrder(data.PrintOrder || '');
        setBookId(data.BookId || '');
        setCreationDate(convertDateForInput(data.CreationDate) || '');
        setCurrentEditionDate(convertDateForInput(data.CurrentEditionDate) || '');
      } else {
        console.log('No matching data found for Book code:', cleanedBookCode);
        toast.error('No data found for the provided Book Code');
      }
    }
    //  else {
    //   toast.error('No data returned from the API');
    // }
  } catch (error) {
    console.error('Error fetching book data:', error);
    toast.error('Error fetching book data');
  }
};

  






const handleSubmit = async (e) => {
  e.preventDefault();
  if (!validateForm()) return;

  const formattedFilingDate = moment(FillingDate).format('YYYY-MM-DD');
  const formattedCreationdate = moment(CreationDate).format('YYYY-MM-DD');
  const fomattedCurrenteditiondate = moment(CurrentEditionDate).format('YYYY-MM-DD');
  const data = {
    BookCode:BookCode,
    BookName:BookName,
    BookNameMarathi: BookNameMarathi,
    BookGroupId : BookGroupId,
    BookStandardId: BookStandardId,
    PublicationId : PublicationId,
    UniversityId : UniversityId,
    BookMediumId : BookMediumId,
    CurrentEdition : CurrentEdition,
    BookRate : BookRate,
    BookPages : BookPages,
    BookForms : BookForms,
    FillingDate: formattedFilingDate,
    TitlePages : TitlePages,
    TitlePressId : TitlePressId,
    PaperSizeId : PaperSizeId,
    PressId : PressId,
    Status :Status,
    OpeningStock :OpeningStock,
    ReprintFlag : ReprintFlag,
    PrintOrder: PrintOrder,
    BookId: BookId,
    CreationDate: formattedCreationdate,
    CurrentEditionDate : fomattedCurrenteditiondate,
    ...(isEditing && { Id: id }), // Only include Id if editing
  };

  const url = isEditing
    ? "https://publication.microtechsolutions.co.in/php/Bookupdate.php"
    : "https://publication.microtechsolutions.co.in/php/Bookpost.php";

  //     // If editing, include the author ID in the payload
  // if (isEditing) {
  //   data.Id = id;
  // }

  try {
    await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    toast.success(isEditing ? 'Book updated successfully!' : 'Book added successfully!');
    resetForm();
    setIsModalOpen(false);
    fetchAllBooks();
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Error saving record!';
    toast.error(errorMessage);
  }
};


  const handleDelete = (index, Id) => {
    setDeleteIndex(index);
    setDeleteId(Id)
    setIsDeleteDialogOpen(true); // Show confirmation dialog
  };



  const confirmDelete = ()=> {
    const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
  const urlencoded = new URLSearchParams();
  urlencoded.append("Id", deleteId);
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow"
  };
  
  fetch("https://publication.microtechsolutions.co.in/php/Bookdelete.php", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.error(error));
    fetchAllBooks()

    setIsDeleteDialogOpen(false);
   }
 
  





  const cancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setDeleteIndex(null);
  };




  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 5, // Reduced size
        Cell: ({ row }) => row.index + 1,
      },  {
        accessorKey: "BookId",
        header: "Book Id",
        size: 5, // Reduced size
      },
      {
        accessorKey: "BookName",
        header: "Book Name",
        size: 2, // Reduced size
      },
      {
        accessorKey: "BookNameMarathi",
        header: "Book Name Marathi",
        size: 2, // Reduced size
      },
      
    
     
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150, // Reduced size
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px",
              }}>Edit
            </Button>
            <Button
              onClick={() => handleDelete(row.index, row.original.Id)}
              style={{
                background: "red",
                color: "white",
                fontSize: '22px',
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [books]
  );
  
  const table = useMaterialReactTable({
    columns,
    data: books,
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF",
        color: "black",
        fontSize: '16px',
      },
    },
  });
  
  return (
    <>
    <div className="book-container">
      <h1> Book Master </h1>


         {/* Show loading message/spinner if data is loading */}
        {loading ? (
          <div className="loadingbook-container">
            <CircularProgress /> {/* Spinner */}
            <p>Loading Books... Please wait.</p> {/* Loading message */}
          </div>
        ) : (
   <div className="booktable-master">
       <div className="booktable1-master">
       <Button onClick={handleNewClick} style={{ color: "#FFFF", fontWeight: "700", background:'#0a60bd', width:'15%'}}>New</Button>
     <div className="booktable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>
        </div>
        )}

        {isModalOpen && <div className="book-overlay" onClick={() => setIsModalOpen(false)} />}
     <Modal open={isModalOpen} >
          <div className="book-modal">
            <h2
              style={{
                textAlign: "center",
                fontWeight: "620",
                margin: "2px",fontSize:"27px"
              }}>
              {editingIndex >= 0 ? "Edit Book" : "Add Book"}
            </h2>
            <form onSubmit={handleSubmit} className="masterbook-form">
              <div>
                <label className="book-label">Book Code<b className="required">*</b></label>{" "}
                <div>
                  <input
                    type="text"
                    id="BookCode"
                    name="BookCode"
                    value={BookCode}
                    // onChange={(e) => setBookCode(e.target.value)}
                    onChange={handleBookcodechange}
                    maxLength={10}
                    ref={bookcodeRef}
                    onKeyDown={(e) => handleKeyDown(e, booknameRef)} 
                    className="masterbook-control"
                    placeholder="Enter Book Code"
                  />
            <div>
        {errors.BookCode && <b className="error-text">{errors.BookCode}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BookName"
                    name="BookName"
                    value={BookName}
                    onChange={(e) => setBookName(e.target.value)}
                    maxLength={100}
                    ref={booknameRef}
                    onKeyDown={(e) => handleKeyDown(e, booknamemarathiRef)} 
                    className="masterbook-control"
                    placeholder="Enter Book Name"
                  />

<div>
                    {errors.BookName && <b className="error-text">{errors.BookName}</b>}
                  </div>
                </div>
              </div> 



              <div>
  <label className="book-label">Book Name Marathi<b className="required">*</b></label>
  <div>
   


<input
  type="text"
  id="BookNameMarathi"
  name="BookNameMarathi"
  value={BookNameMarathi}
  onChange={(e)=> setBookNameMarathi(e.target.value)}
  maxLength={200}
  className="marathibook-control"
  ref={booknamemarathiRef}
  onKeyDown={(e) => handleKeyDown(e, bookgroupIdRef)} 
  onFocus={handleFocus}
  onBlur={handleBlur}
  // style={{ fontFamily: "Shivaji"}}
  lang="mr"
  placeholder="पुस्तकाचे नाव प्रविष्ट करा"

/>
    

    {errors.BookNameMarathi && <b className="error-text">{errors.BookNameMarathi}</b>}
  </div>
</div>



              <div>
                <label className="book-label">Book Group<b className="required">*</b></label>
                <div>
                  <Select
                    id="BookGroupId"
                    name="BookGroupId"
                    value={bookgroupOptions.find((option) => option.value === BookGroupId)}
                    onChange={(option) => setBookGroupId(option.value)}

                    ref={bookgroupIdRef}
                    onKeyDown={(e) => handleKeyDown(e, bookstandardidRef)} 
                    options={bookgroupOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select  Group"
                  />

<div>
                    {errors.BookGroupId && <b className="error-text">{errors.BookGroupId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Standard<b className="required">*</b></label>
                <div>
                <Select
                    id="BookStandardId"
                    name="BookStandardId"
                    value={bookstandardOptions.find((option) => option.value === BookStandardId)}
                    onChange={(option) => setBookStandardId(option.value)}
                    ref={bookstandardidRef}
                    onKeyDown={(e) => handleKeyDown(e, publicationRef)} 
                    options={bookstandardOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                        marginTop: "10px",marginBottom: '5px',
                        border: '1px solid rgb(223, 222, 222)',
                        borderRadius:'4px',
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 100,
                      }),
                    }}
                    placeholder="Select Standard "
                  />

<div>
                    {errors.BookStandardId && <b className="error-text">{errors.BookStandardId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Publication<b className="required">*</b></label>{" "}
                <div>
                <Select
                    id="PublicationId"
                    name="PublicationId"
                    value={publicationOptions.find((option) => option.value === PublicationId)}
                    onChange={(option) => setPublicationId(option.value)}
                    ref={publicationRef}
                    onKeyDown={(e) => handleKeyDown(e, universityRef)} 
                    options={publicationOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Publication "
                  />

<div>
                    {errors.PublicationId && <b className="error-text">{errors.PublicationId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">University<b className="required">*</b></label>
                <div>
                <Select
                    id="UniversityId"
                    name="UniversityId"
                    value={universityOptions.find((option) => option.value === UniversityId)}
                    onChange={(option) => setUniversityId(option.value)}
                    ref={universityRef}
                    onKeyDown={(e) => handleKeyDown(e, bookmediumRef)} 
                    options={universityOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select University "
                  />

<div>
                    {errors.UniversityId && <b className="error-text">{errors.UniversityId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Medium<b className="required">*</b></label>
                <div>
                <Select
                    id="BookMediumId"
                    name="BookMediumId"
                    value={bookmediumOptions.find((option) => option.value === BookMediumId)}
                    onChange={(option) => setBookMediumId(option.value)}

                    ref={bookmediumRef}
                    onKeyDown={(e) => handleKeyDown(e, currenteditionRef)} 
                    options={bookmediumOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select Medium "
                  />

<div>
                    {errors.BookMediumId && <b className="error-text">{errors.BookMediumId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Current Edition</label>
                <div>
                  <input
                    type="text"
                    id="CurrentEdition"
                    name="CurrentEdition"
                    value={CurrentEdition}
                    onChange={(e) => setCurrentEdition(e.target.value)}
                    maxLength={20}
                    ref={currenteditionRef}
                    onKeyDown={(e) => handleKeyDown(e, bookrateRef)} 
                    className="masterbook-control"
                    placeholder="Enter Current Edition"
                  />

<div>
                    {errors.CurrentEdition && <b className="error-text">{errors.CurrentEdition}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Rate<b className="required">*</b></label>{" "}
                <div>
                <input
                type="text" // Change to "text" to handle decimal input more flexibly
                   id="BookRate"
                  name="BookRate"
                 value={BookRate}
                 onChange={(e) => {
                 const value = e.target.value;

                   // Regex to validate decimal numbers with at most 18 digits total and 2 decimal places
                   const regex = /^\d{0,18}(\.\d{0,2})?$/;

                   // Check if the value matches the regex
                    if (value === '' || regex.test(value)) {
                    setBookRate(value);
                }
                  }}
                  ref={bookrateRef}
                  onKeyDown={(e) => handleKeyDown(e, bookpagesRef)} 
                className="masterbook-control"
                   placeholder="Enter Book Rate"
/>

                  <div>
                    {errors.BookRate && <b className="error-text">{errors.BookRate}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Pages<b className="required">*</b></label>
                <div>
                  <input
                    type="number"
                    id="BookPages"
                    name="BookPages"
                    value={BookPages}
                    onChange={(e) => setBookPages(e.target.value)}
                    ref={bookpagesRef}
                    onKeyDown={(e) => handleKeyDown(e, bookformsRef)} 
                    className="masterbook-control"
                    placeholder="Enter Book Pages"
                  />

<div>
                    {errors.BookPages && <b className="error-text">{errors.BookPages}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Book Forms<b className="required">*</b></label>
                <div>
                  <input
                    type="number"
                    id="BookForms"
                    name="BookForms"
                    value={BookForms}
                    onChange={(e) => setBookForms(e.target.value)}
                    ref={bookformsRef}
                    onKeyDown={(e) => handleKeyDown(e, fillingdateRef)} 
                    className="masterbook-control"
                    placeholder="Enter Book Forms"
                  />
                  <div>
                    {errors.BookForms && <b className="error-text">{errors.BookForms}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Filling Date<b className="required">*</b></label>
                <div>
                  <input
                    type="date"
                    id="FillingDate"
                    name="FillingDate"
                    value={FillingDate}
                    onChange={(e) => setFilingDate(e.target.value)}
                    ref={fillingdateRef}
                    onKeyDown={(e) => handleKeyDown(e, titlepagesRef)} 
                     className="masterbook-control"
                    placeholder="Enter Filing Date"
                  />

<div>
                    {errors.FillingDate && <b className="error-text">{errors.FillingDate}</b>}
                  </div>
                </div>
              </div>

              <div>
                <label className="book-label">Title Pages<b className="required">*</b></label>
                <div>
                  <input
                    type="number"
                    id="TitlePages"
                    name="TitlePages"
                    value={TitlePages}
                    onChange={(e) => setTitlePages(e.target.value)}
                    ref={titlepagesRef}
                    onKeyDown={(e) => handleKeyDown(e, titlepressRef)} 
                     className="masterbook-control"

                    placeholder="Enter Title pages"
                  />

<div>
                    {errors.TitlePages && <b className="error-text">{errors.TitlePages}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Title Press<b className="required">*</b></label>{" "}
                <div>
                <Select
                    id="TitlePressId"
                    name="TitlePressId"
                    value={titlepressOptions.find((option) => option.value === TitlePressId)}
                    onChange={(option) => setTitlePressId(option.value)}
                    ref={titlepressRef}
                    onKeyDown={(e) => handleKeyDown(e, papersizeRef)} 
                    options={titlepressOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select title press "
                  />

<div>
                    {errors.TitlePressId && <b className="error-text">{errors.TitlePressId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Paper Size<b className="required">*</b></label>
                <div>
                <Select
                    id="PaperSizeId"
                    name="PaperSizeId"
                    value={papersizeOptions.find((option) => option.value === PaperSizeId)}
                    onChange={(option) => setPaperSizeId(option.value)}
                    ref={papersizeRef}
                    onKeyDown={(e) => handleKeyDown(e, pressRef)} 
                    options={papersizeOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select papersize "
                  />

<div>
                    {errors.PaperSizeId && <b className="error-text">{errors.PaperSizeId}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Press<b className="required">*</b></label>
                <div>
                <Select
                    id="PressId"
                    name="PressId"
                    value={pressOptions.find((option) => option.value === PressId)}
                    onChange={(option) => setPressId(option.value)}
                    ref={pressRef}
                    onKeyDown={(e) => handleKeyDown(e, statusRef)} 
                    options={pressOptions}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px",
                               marginTop: "10px",
                               borderRadius: "4px",
                               border: "1px solid rgb(223, 222, 222)",
                                marginBottom: '5px'
                      }),
                      menu: (base) => ({
                       ...base,
                       zIndex: 100,
                     }),
                    }}
                    placeholder="Select Press "
                  />
                  <div>
                    {errors.PressId && <b className="error-text">{errors.PressId}</b>}
                  </div>
                </div>
              </div>{" "}
              <div>
                <label className="book-label">Status<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="Status"
                    name="Status"
                    value={Status}
                    onChange={(e) => setStatus(e.target.value)}
                    maxLength={50}
                    ref={statusRef}
                    onKeyDown={(e) => handleKeyDown(e, openingstockRef)} 
                    className="masterbook-control"
                    placeholder="Enter Status"
                  />

<div>
                    {errors.Status && <b className="error-text">{errors.Status}</b>}
                  </div>
                </div>
              </div>
              <div>
                <label className="book-label">Opening stock<b className="required">*</b></label>
                <div>
                  <input
                    type="number"
                    id="OpeningStock"
                    name="OpeningStock"
                    value={OpeningStock}
                    onChange={(e) => setOpeningStock(e.target.value)}
                    ref={openingstockRef}
                    onKeyDown={(e) => handleKeyDown(e, reprintflagRef)} 
                    className="masterbook-control"
                    placeholder="Enter Opening Stock"
                  />

<div>
                    {errors.OpeningStock && <b className="error-text">{errors.OpeningStock}</b>}
                  </div>
                </div></div>


                <div>
                <label className="book-label">Reprint Flag<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="ReprintFlag"
                    name="ReprintFlag"
                    value={ReprintFlag}
                    onChange={(e) => setReprintFlag(e.target.value)}
                    maxLength={1}
                    ref={reprintflagRef}
                    onKeyDown={(e) => handleKeyDown(e, printorderRef)} 
                    className="masterbook-control"
                    placeholder="Enter Reprint Flag"
                  />

<div>
                    {errors.ReprintFlag && <b className="error-text">{errors.ReprintFlag}</b>}
                  </div>
                </div></div>

                <div>
                <label className="book-label">Print Order</label>
                <div>
                  <input
                    type="number"
                    id="PrintOrder"
                    name="PrintOrder"
                    value={PrintOrder}
                    onChange={(e) => setPrintOrder(e.target.value)}
                    ref={printorderRef}
                    onKeyDown={(e) => handleKeyDown(e, bookidRef)} 
                    className="masterbook-control"
                    placeholder="Enter Print Order"
                  />

<div>
                    {errors.PrintOrder && <b className="error-text">{errors.PrintOrder}</b>}
                  </div>
                </div></div>

                <div>
                <label className="book-label">Book Id<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="BookId"
                    name="BookId"
                    value={BookId}
                    onChange={(e) => setBookId(e.target.value)}
                    ref={bookidRef}
                    onKeyDown={(e) => handleKeyDown(e, creationdateRef)} 
                    className="masterbook-control"
                    placeholder="Enter Book Id"
                  />

<div>
                    {errors.BookId && <b className="error-text">{errors.BookId}</b>}
                  </div>
                </div></div>

                <div>
                <label className="book-label">Creation Date<b className="required">*</b></label>
                <div>
                  <input
                    type="date"
                    id="CreationDate"
                    name="CreationDate"
                    value={CreationDate}
                    onChange={(e) => setCreationDate(e.target.value)}
                    ref={creationdateRef}
                    onKeyDown={(e) => handleKeyDown(e, curreditiondateRef)} 
                    className="masterbook-control"
                    placeholder="Enter Creation Date"
                  />

<div>
                    {errors.CreationDate && <b className="error-text">{errors.CreationDate}</b>}
                  </div>
                </div></div>

                <div>
                <label className="book-label">Current Edition Date<b className="required">*</b></label>
                <div>
                  <input
                    type="date"
                    id="CurrentEditionDate"
                    name="CurrentEditionDate"
                    value={CurrentEditionDate}
                    onChange={(e) => setCurrentEditionDate(e.target.value)}
                    ref={curreditiondateRef}
                    onKeyDown={(e) => handleKeyDown(e, saveRef)} 
                    className="masterbook-control"
                    placeholder="Enter Current Edition Date"
                  />

<div>
                    {errors.CurrentEditionDate && <b className="error-text">{errors.CurrentEditionDate}</b>}
                  </div>
                </div></div>

              
                </form>
              
              <div className="book-btn-container">
                <Button
                onClick={handleSubmit}
                ref={saveRef}
                  type="submit"
                  style={{
                    background: "#0a60bd",
                   alignContent:'center',
                    color: "white",
                  }}>
                  {editingIndex >= 0 ? "Update" : "Save"}
                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>


        
        {/* Confirmation Dialog for Delete */}
        <Dialog
          open={isDeleteDialogOpen}
          onClose={cancelDelete}
        >
          <DialogTitle style={{color:'navy', fontWeight:'600'}}>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this <b style={{color:'red'}}><u>Book</u></b>?
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete}  style={{
                background: "red",
                color: "white",
                marginRight: "5px", 
              }}>
              Cancel
            </Button>
            <Button onClick={confirmDelete}  style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer/>
    {/* </div> */}
    </>
  );
}

export default Book;
