import React, {useState} from 'react';
import './Royalty.css'; 
import { Button } from '@mui/material';  
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Royalty() {
  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintingModalOpen, setIsPrintingModalOpen] = useState(false); 

  const [printData, setPrintData] = useState([
    { Srno: 1, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 1000.00, Discount: 50, DiscAmount:1550.00 },
    { Srno: 2, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 5000.00, Discount: 50, DiscAmount:1000.00 },
    { Srno: 3, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 2500.00, Discount: 50, DiscAmount:250.00 },
    
   
  ]);

  const handlePrint = () => {
    setIsPrinting(true);
  
    // Hide print and cancel buttons
    const printButton = document.querySelector('.royaltyprint-button');
    const cancelButton = document.querySelector('.royaltycancel-button');
    const inputs = document.querySelectorAll('.royaltyprint-control');
  
    if (printButton) printButton.style.display = 'none';
    if (cancelButton) cancelButton.style.display = 'none';
  
    // Remove borders from input fields
    inputs.forEach(input => input.style.border = 'none');
  
    const modalContent = document.querySelector('.royaltyprint-container');
    if (!modalContent) {
      console.error('Printing modal content not found!');
      return;
    }
  
    setTimeout(() => {
      html2canvas(modalContent, {
        scale: 3,
        logging: false,
        useCORS: true,
        dpi: 300,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
  
        const pdf = new jsPDF('p', 'mm', 'A4'); // A4 size (210mm x 297mm)
        const imgWidth = 190;  // Image width (in mm), keeping it slightly smaller to fit
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
  
        // Start with adding the first page of the content
        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  
        // Check if the content overflows the A4 page height (297mm)
        const pageHeight = 297; // A4 page height in mm
        const totalHeight = imgHeight + 20; // Add padding/margin space
  
        // If the content overflows, add more pages
        if (totalHeight > pageHeight) {
          const remainingHeight = totalHeight - pageHeight; // Amount of content that doesn't fit
          let startY = pageHeight + 10; // Start at the top of the next page
  
          // Split the content across multiple pages
          while (remainingHeight > 0) {
            pdf.addPage();  // Add new page
            pdf.addImage(imgData, 'PNG', 10, startY, imgWidth, imgHeight); // Add image to the next page
            remainingHeight -= pageHeight; // Decrease remaining height for the next page
            startY = 10;  // Start new content at the top of the page
          }
        }
  
        // Download the generated PDF
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'royalty.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        setIsPrintingModalOpen(false);
  
        // Restore print and cancel buttons after printing
        if (printButton) printButton.style.display = 'flex';
        if (cancelButton) cancelButton.style.display = 'flex';
  
        // Restore borders for input fields
        inputs.forEach(input => input.style.border = '1px solid rgb(223, 222, 222)');
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF.');
      });
    }, 500);
  };
  return (
    <div className="royaltyprint-container">
      <h1>Royalty Statement</h1>

      {/* Header Section */}
      <div className="headerroyaltyprint-section">
      <div className="leftroyaltyprint-header">
  <div className="accounting-year"><strong>Accounting Year:</strong> 2024</div>
  <div><strong>Author/S:</strong> John Doe</div>
</div>

        <div className="rightroyaltyprint-header">
          <div><strong>No:</strong> 12345</div>
          <div><strong>Date:</strong> 2024-11-14</div>
        </div>
      </div>

      {/* Table Section */}
      <div className="tableroyaltyprint-section">
        <table className="royaltyprint-table">
          <thead>
          <tr>
              <th>Sr.No</th>
              <th>Title Code No</th>
              <th>Class</th>
              <th>TITLE</th>
              <th>Copies</th>
              <th>Price</th>
              <th>Amount <br/>
              <div style={{display:'flex', justifyContent:'space-around'}}><label>Rs.</label><label>Ps.</label></div>
              </th>
              <th>Discount</th>
              <th>Amount
              <div style={{display:'flex', justifyContent:'space-around'}}><label>Rs.</label><label>Ps.</label></div>

              </th>
            </tr>
          </thead>
          <tbody>
            {printData.map((data, index) => (
              <tr key={index}>
                <td>{data.Srno}</td>
                <td>{data.TitleCodeno}</td>
                <td>{data.Class}</td>
                <td>{data.TITLE}</td>
                <td>{data.Copies}</td>
                <td>{data.Amount}</td>
                <td>{data.Copies * data.Amount}</td> {/* Calculate row total */}
                <td>{data.Discount}</td>
                <td>{data.DiscAmount}</td>
              </tr>
            ))}
            <tr>

            <td colSpan="2" className="royalty-distribution">
               <label>Distribution of Royalty:</label>
              </td>
              <td colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
              <td colSpan="8" style={{ fontWeight: 'bold' }}>Rs. 8000</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Footer Section */}

      <div>
     <p style={{fontWeight:'600', marginTop:'20px', float:'left'}}>Authorised Signatory</p>

     </div>
      <div className="footerroyaltyprint-section">
    
          <p>Yours Faithfully,</p>
          <p>For Phadke Prakashan</p>
        </div>
        <div className="royaltyprint-btn-container">
      <Button 
  onClick={handlePrint}
  className="royaltyprint-button"
  style={{
    background: "#0a60bd",
    color: "white",
    marginRight: "5px",
  }}
>
  Print
</Button>
<Button 
  className="royaltycancel-button"
  style={{ 
    background: "red",
    color: "white",
  }}
>
  Cancel
</Button>

      </div>
     
    </div>
  );
}

export default Royalty;
