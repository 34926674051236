import React from 'react'

function RoyaltyStatementSummary() {
  return (
    <div>
      RoyaltyStatementSummary
      RoyaltyStatementSummary
    </div>
  )
}

export default RoyaltyStatementSummary
