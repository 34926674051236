import React from 'react'

function RoyaltyLetter() {
  return (
    <div>
      RoyaltyLetter
    </div>
  )
}

export default RoyaltyLetter
