import React, { useState, useEffect } from 'react';
import './Canvassorbillprint.css';
import { Button } from '@mui/material';  
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Canvassorbillprint() {

  const [YourOrderNo, setYourOrderNo] = useState('');
  const [Dated1, setDated1] =useState('');
  const [ParcelSentThrough, setParcelsentthrough] = useState('');
  const [ReceiptNo, setReceiptNo] = useState('');
  const [Dated2, setDated2] = useState('');
  const [Bundles, setBundles] = useState('');
  const [Weight, setWeight] = useState('');
  const [Freight, setFreight] = useState('');
  const [ReceiptSentThrough, setReceiptsentthrough] = useState('');



  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintingModalOpen, setIsPrintingModalOpen] = useState(false); 

  const [formData, setFormData] = useState({
    address: 'Atharva Apt. Flat No.10, T.P.S.No 7/ 217 /7 A, Dudhali, KOLHAPUR: 416 012',
    phone: '(0231) 25 40 211(6 Lines)',
    date: '2024-11-12',
    challanNo: '12345',
    gstNo: '27AABFP6963E1Z4',
    hsnNo: '4901',
  });

  const [inputValues, setInputValues] = useState(
    new Array(9).fill('') 
  );

  const [printData, setPrintData] = useState([
    { Srno: 1, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 200, Discount: 50, DiscAmount:50 },
    { Srno: 2, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 500, Discount: 50, DiscAmount:50 },
    { Srno: 3, TitleCodeno: 'A101', Class: 'Class A', TITLE: 'Product 1', Copies: 5, Amount: 4520, Discount: 50, DiscAmount:50 },
    
   
  ]);

  const calculateTotal = () => {
    return printData.reduce((total, item) => {
      return total + item.Copies * item.Amount;
    }, 0);
  };

  const handlePrint = () => {
    setIsPrinting(true);
  
    // Hide print and cancel buttons
    const printButton = document.querySelector('.convassorbillprint-button');
    const cancelButton = document.querySelector('.convassorbillcancel-button');
    const inputs = document.querySelectorAll('.inputconvassorbillprint-control');
  
    if (printButton) printButton.style.display = 'none';
    if (cancelButton) cancelButton.style.display = 'none';
  
    // Remove borders from input fields
    inputs.forEach(input => input.style.border = 'none');
  
    const modalContent = document.querySelector('.convassorbillprint-container');
    if (!modalContent) {
      console.error('Printing modal content not found!');
      return;
    }
  
    setTimeout(() => {
      html2canvas(modalContent, {
        scale: 3,
        logging: false,
        useCORS: true,
        dpi: 300,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
  
        const pdf = new jsPDF('p', 'mm', 'A4'); // A4 size (210mm x 297mm)
        const imgWidth = 190;  // Image width (in mm), keeping it slightly smaller to fit
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
  
        // Start with adding the first page of the content
        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
  
        // Check if the content overflows the A4 page height (297mm)
        const pageHeight = 297; // A4 page height in mm
        const totalHeight = imgHeight + 20; // Add padding/margin space
  
        // If the content overflows, add more pages
        if (totalHeight > pageHeight) {
          const remainingHeight = totalHeight - pageHeight; // Amount of content that doesn't fit
          let startY = pageHeight + 10; // Start at the top of the next page
  
          // Split the content across multiple pages
          while (remainingHeight > 0) {
            pdf.addPage();  // Add new page
            pdf.addImage(imgData, 'PNG', 10, startY, imgWidth, imgHeight); // Add image to the next page
            remainingHeight -= pageHeight; // Decrease remaining height for the next page
            startY = 10;  // Start new content at the top of the page
          }
        }
  
        // Download the generated PDF
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'convassorbill.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        setIsPrintingModalOpen(false);
  
        // Restore print and cancel buttons after printing
        if (printButton) printButton.style.display = 'flex';
        if (cancelButton) cancelButton.style.display = 'flex';
  
        // Restore borders for input fields
        inputs.forEach(input => input.style.border = '1px solid rgb(223, 222, 222)');
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF.');
      });
    }, 500);
  };
  
  

  const handleInputChange = (index, value) => {
    const updatedValues = [...inputValues];
    updatedValues[index] = value;
    setInputValues(updatedValues);
  };

  return (
    <div className="convassorbillprint-container">
      <div className="canvassorbillprint-section">

        <div className='convassorbillprintheader-section'>
        <div className="convassorbillprintto-section">
          <textarea className='convassorbillprint-textarea'>To</textarea>
        </div>
        <div className="convassorbillprintaddress-section">
          <p>{formData.address}</p>
          <p>{formData.phone}</p>
        </div>
        </div>
       
      </div>

      <div className="convassorbillprint-details">
        <h2 className='debitadvice'> DEBIT ADVICE</h2>
        <h3>FOR COMPLEMENTARY COPIES SENT.</h3>
        <p><b>Date:</b> {formData.date}</p>
        <p><b>Challan No:</b> {formData.challanNo}</p>
        {/* <p><b>GSTIN No:</b> {formData.gstNo}</p>
        <p><b>Books HSN No.</b> {formData.hsnNo}</p> */}
      </div>


<hr/>
      {/* Input Fields Section */}
      <div className="inputconvassorbillprint-fields">
       <div>
        <label className='convassorbillprint-label'>
          Your Order No
        </label>
        <div>
          <input
          type='text'
          id='YourOrderNo' name='YourOrderNo' value={YourOrderNo} className='convassorbillprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>Dated        </label>
        <div>
          <input
          type='date'
          id='Dated1' name='Dated1' value={Dated1} className='convassorbillprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>          Parcel Sent Through
        </label>
        <div>
          <input
          type='text'
          id='ParcelSentThrough' name='ParcelSentThrough' value={ParcelSentThrough}  className='convassorbillprint-control'readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>          Receipt No
        </label>
        <div>
          <input
          type='text'
          id='ReceiptNo' name='ReceiptNo' value={ReceiptNo}  className='convassorbillprint-control'readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>          Dated
        </label>
        <div>
          <input
          type='date'
          id='Dated2' name='Dated2' value={Dated2}  className='convassorbillprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>         Bundles
        </label>
        <div>
          <input
          type='text'
          id='Bundles' name='Bundles' value={Bundles}  className='convassorbillprint-control'readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>          Weight Kgs
        </label>
        <div>
          <input
          type='text'
          id='Weight' name='Weight' value={Weight} className='convassorbillprint-control' readOnly
          />
        </div>
       </div>
       <div>
       <label className='convassorbillprint-label'>          Freight Kgs
        </label>
        <div>
          <input
          type='text'
          id='Freight' name='Freight' value={Freight}  className='convassorbillprint-control' readOnly
          />
        </div>
       </div>

       <div>
       <label className='convassorbillprint-label'>          Receipt Sent Through
        </label>
        <div>
          <input
          type='text'
          id='ReceiptSentThrough' name='ReceiptSentThrough' value={ReceiptSentThrough}  className='convassorbillprint-control' readOnly
          />
        </div>
       </div>
      </div>
<hr style={{color:'1px solid orange'}}/>
      {/* Table Section */}
      <div className="tableconvassorbillprint-section">
        <table>
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Title Code No</th>
              <th>Class</th>
              <th>TITLE</th>
              <th>Copies</th>
              <th>Price</th>
              <th>Amount </th>
             
            </tr>
          </thead>
          <tbody>
            {printData.map((data, index) => (
              <tr key={index}>
                <td>{data.Srno}</td>
                <td>{data.TitleCodeno}</td>
                <td>{data.Class}</td>
                <td>{data.TITLE}</td>
                <td>{data.Copies}</td>
                <td>{data.Amount}</td>
                <td>{data.Copies * data.Amount}</td> {/* Calculate row total */}
               
              </tr>
            ))}
            <tr>
              <td colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
              <td style={{ fontWeight: 'bold' }}>{calculateTotal()}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="footerconvassorbillprint">
        <p> For Phadke Prakashan </p>
      </div>

      {/* Buttons */}
      <div className="convassorbillprint-btn-container">
      <Button 
  onClick={handlePrint}
  className="convassorbillprint-button"
  style={{
    background: "#0a60bd",
    color: "white",
    marginRight: "5px",
  }}
>
  Print
</Button>
<Button 
  className="convassorbillcancel-button"
  style={{ 
    background: "red",
    color: "white",
  }}
>
  Cancel
</Button>

      </div>
    </div>
  );
}

export default Canvassorbillprint;
