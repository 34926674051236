import React, { useState, useMemo , useEffect, useRef} from "react";
import "./ProfCategory.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { RiDeleteBin5Line } from "react-icons/ri";

function ProfCategory() {
  const [CategoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState("");
  const [errors, setErrors]  = useState('');

  const [deleteId, setDeleteId] = useState(null);



    // Confirmation Dialog state
       const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
       const [deleteIndex, setDeleteIndex] = useState(null);
  

         const profcatRef = useRef(null);
              const saveRef = useRef(null);
              
                const handleKeyDown = (e, nextFieldRef) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    if (nextFieldRef && nextFieldRef.current) {
                      nextFieldRef.current.focus();
                    }
                  }
                };


const fetchProfCategories = async ()=> {
  try {
    const response = await axios.get("https://publication.microtechsolutions.co.in/php/ProfessorCategoryget.php");
    setCategories(response.data);
  } catch (error) {
    toast.error("Error fetching Professor Categories:", error);
  }
}

useEffect(()=>{
  fetchProfCategories();
}, [])


  

  
  


  

  const resetForm = () => {
    setCategoryName('');
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const category = categories[row.index];
    setCategoryName(category.CategoryName);
   
    setEditingIndex(row.index);
    setIsEditing(true);

    setIsModalOpen(true);
    setId(category.Id);
  };

 const handleDelete = (index, Id) => {
       setDeleteIndex(index);
       setDeleteId(Id)
       setIsDeleteDialogOpen(true); // Show confirmation dialog
     };
 
 
     const confirmDelete = () => {
        const myHeaders = new Headers();
                         myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                         
                         const urlencoded = new URLSearchParams();
                         urlencoded.append("Id", deleteId);
                         
                         const requestOptions = {
                           method: "POST",
                           headers: myHeaders,
                           body: urlencoded,
                           redirect: "follow"
                         };
                         
                         fetch("https://publication.microtechsolutions.co.in/php/Professorcategorydelete.php", requestOptions)
                           .then((response) => response.text())
                           .then((result) => console.log(result))
                           .catch((error) => console.error(error));
                           toast.success('Prof Category Deleted Successfully')
                           setIsDeleteDialogOpen(false);
                           fetchProfCategories()
     };
 
   
     const cancelDelete = () => {
       setIsDeleteDialogOpen(false);
       setDeleteIndex(null);
     };
 


  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;

    
    if (!CategoryName) {
        formErrors.CategoryName = "Professor Category is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      CategoryName: CategoryName
      
    };
  
    // Determine the URL based on whether we're editing or adding
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/ProfessorCategoryupdate.php"
      : "https://publication.microtechsolutions.co.in/php/ProfessorCategorypost.php";
  
    // If editing, include the author ID in the payload
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Professor Category updated successfully!');
      } else {
        toast.success('Professor Category added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchProfCategories(); // Refresh the list after submit
    } catch (error) {
      // console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };

  const columns = useMemo(
    () => [

      
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "CategoryName",
        header: "Professor Category Name",
        size: 50,
      },
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
               <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index, row.original.Id)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [categories]
  );

  const table = useMaterialReactTable({
    columns,
    data: categories, muiTableHeadCellProps: {
      style: {
          backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="partycat-container">
      <h1>Professor Category Master</h1>
      <div className="partycattable-master">
        <div className="partycattable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF",
             fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="partycattable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="partycat-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen}>
          <div className="partycat-modal">
            <h2
             style={{
              textAlign: "center",
              fontWeight: "620",
              margin: "2px",fontSize:"27px"
              }}>
              {editingIndex >= 0 ? "Edit Professor Category " : "Add Professor Category"}
            </h2>
            <form onSubmit={handleSubmit} className="partycat-form">
              <div>
                <label className="partycat-label">Professor Category Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CategoryName"
                    name="CategoryName"
                    value={CategoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    maxLength={50}
                    ref={profcatRef} 
                    onKeyDown={(e) => handleKeyDown(e, saveRef)} 
                    className="partycat-control"
                    placeholder="Enter prof category"
                  />

                  <div>
                  {errors.CategoryName && <b className="error-text">{errors.CategoryName}</b>}
                  </div>
                </div></div>
                </form>
              
             
              <div className="partycat-btn-container">
                <Button
                  type="submit" onClick={handleSubmit}
                  ref={saveRef} 
                  // onKeyDown={(e) => handleKeyDown(e, accgroupnameRef)} 
                  style={{
                    background: "#0a60bd",
                    alignContent: 'center',
                    color: "white",
                  }}>
               {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}>
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>

         {/* Confirmation Dialog for Delete */}
                        <Dialog
                          open={isDeleteDialogOpen}
                          onClose={cancelDelete}
                        >
                          <DialogTitle style={{color:'navy', fontWeight:'600'}}>Confirm Deletion</DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this <b style={{color:'red'}}><u>Professor Category</u></b>?
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={cancelDelete}  style={{
                                background: "red",
                                color: "white",
                                marginRight: "5px", 
                              }}>
                              Cancel
                            </Button>
                            <Button onClick={confirmDelete}  style={{
                                background: "#0a60bd",
                                color: "white",
                                marginRight: "5px", 
                              }}>
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default ProfCategory;
