import React from 'react'

function RoyaltyStatementAuthorwise() {
  return (
    <div>
      RoyaltyStatementAuthorwise
      RoyaltyStatementAuthorwise
    </div>
  )
}

export default RoyaltyStatementAuthorwise
