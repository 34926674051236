import React, { useState, useMemo, useEffect, useRef } from "react";
import "./CostCenter.css";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Modal, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";


function CostCenter() {
  const [CostCenterName, setCostCenterName] = useState('');
  const [costcenters, setCostcenters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [id, setId] = useState('');
  const [errors, setErrors] = useState('');
  const [deleteId, setDeleteId] = useState(null);


  // Confirmation Dialog state
       const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
       const [deleteIndex, setDeleteIndex] = useState(null);
   
  const costcenterRef = useRef(null);
       const saveRef = useRef(null);
       
         const handleKeyDown = (e, nextFieldRef) => {
           if (e.key === 'Enter') {
             e.preventDefault();
             if (nextFieldRef && nextFieldRef.current) {
               nextFieldRef.current.focus();
             }
           }
         };

  const fetchCostcenters = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/Costcenterget.php");
      setCostcenters(response.data);
    } catch (error) {
      toast.error("Error fetching Costcenters:", error);
    }
  };

  useEffect(() => {
    fetchCostcenters();
  }, []);

 

  const resetForm = () => {
    setCostCenterName('');
    setIsEditing(false); // Reset isEditing to false
    setId(''); // Clear the id
    setErrors(''); // Clear any previous errors
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
  };

  const handleEdit = (row) => {
    const mode = costcenters[row.index];
    setCostCenterName(mode.CostCenterName); // Ensure this matches your data field
    setEditingIndex(row.index);
    setId(mode.Id);
    setIsEditing(true); // Ensure isEditing is set to true for editing
    setIsModalOpen(true);
  };

  const handleDelete = (index, Id) => {
       setDeleteIndex(index);
       setDeleteId(Id)
       setIsDeleteDialogOpen(true); // Show confirmation dialog
     };
 
 
     const confirmDelete = () => {
        const myHeaders = new Headers();
                         myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                         
                         const urlencoded = new URLSearchParams();
                         urlencoded.append("Id", deleteId);
                         
                         const requestOptions = {
                           method: "POST",
                           headers: myHeaders,
                           body: urlencoded,
                           redirect: "follow"
                         };
                         
                         fetch("https://publication.microtechsolutions.co.in/php/Costcenterdelete.php", requestOptions)
                           .then((response) => response.text())
                           .then((result) => console.log(result))
                           .catch((error) => console.error(error));
                           toast.success('Cost center Deleted Successfully')
                           setIsDeleteDialogOpen(false);
                           fetchCostcenters()
     }
 
   
     const cancelDelete = () => {
       setIsDeleteDialogOpen(false);
       setDeleteIndex(null);
     };
 

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!CostCenterName) {
      formErrors.CostCenterName = "CostCenter Name is required.";
      isValid = false; 
    }

    setErrors(formErrors);
    return isValid;
  };







const handleSubmit = async (e) => {
  e.preventDefault();

  // Only validate form if it's a POST request (not editing)
  if (!isEditing && !validateForm()) return;

  const data = {
    CostCenterName: CostCenterName,
  };

  const url = isEditing
    ? "https://publication.microtechsolutions.co.in/php/Costcenterupdate.php"
    : "https://publication.microtechsolutions.co.in/php/Costcenterpost.php";

  if (isEditing) {
    data.Id = id;
  }

  try {
    await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (isEditing) {
      toast.success('Costcenter  updated successfully!');
    } else {
      toast.success('Costcenter added successfully!');
    }
    setIsModalOpen(false);
    resetForm();
    fetchCostcenters();
  } catch (error) {
    // console.error("Error saving record:", error);
    toast.error('Error saving record!');
  }
};
  

  const columns = useMemo(
    () => [
      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
     {
        accessorKey: "CostCenterName",
        header: "Costcenter  Name",
        size: 50,
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
            <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index, row.original.Id)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [costcenters]
  );

  const table = useMaterialReactTable({
    columns,
    data: costcenters, 
    muiTableHeadCellProps: {
      style: {
        backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="costcenter-container">
      <h1>Cost Center Master</h1>
      <div className="costcentertable-master">
        <div className="costcentertable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
            fontWeight: "700", background:'#0a60bd', width:'15%' }}
>            New
          </Button>
          <div className="costcentertable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>


        {isModalOpen && <div className="costcenter-overlay" onClick={() => setIsModalOpen(false)} />}

        <Modal open={isModalOpen} >
          <div className="costcenter-modal">
            <h2
                style={{
                  textAlign: "center",
                  fontWeight: "620",
                  margin: "2px",fontSize:"27px", marginBottom:'10px'
              }}>
              {editingIndex >= 0 ? "Edit Cost Center" : "Add Cost Center"}
            </h2>
            <form onSubmit={handleSubmit} className="costcenter-form">
              <div>
                <label className="costcenter-label">Cost Center Name<b className="required">*</b></label>
                <div>
                  <input
                    type="text"
                    id="CostCenterName"
                    name="CostCenterName"
                    value={CostCenterName}
                    onChange={(e) => setCostCenterName(e.target.value)}
                    maxLength={50}
                    ref={costcenterRef} 
                    onKeyDown={(e) => handleKeyDown(e, saveRef)} 
                    className="costcenter-control"
                    placeholder="Enter CostCenter Name"
                  />
                  <div>
{errors.CostCenterName && <b className="error-text">{errors.CostCenterName}</b>}
</div>
                </div>
              </div>
            </form>

            <div className="costcenter-btn-container">
              <Button
                type="submit"
                onClick={handleSubmit}
                ref={saveRef} 
                // onKeyDown={(e) => handleKeyDown(e, accgroupnameRef)} 
                style={{
                  background: "#0a60bd",
                  alignContent: 'center',
                  color: "white",
                }}>
                                                {editingIndex >= 0 ? "Update" : "Save"}

              </Button>
              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  background: "red",
                  color: "white",
                }}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

         {/* Confirmation Dialog for Delete */}
         <Dialog
                  open={isDeleteDialogOpen}
                  onClose={cancelDelete}
                >
                  <DialogTitle style={{color:'navy', fontWeight:'600'}}>Confirm Deletion</DialogTitle>
                  <DialogContent>
                    Are you sure you want to delete this <b style={{color:'red'}}><u>Cost Center</u></b>?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={cancelDelete}  style={{
                        background: "red",
                        color: "white",
                        marginRight: "5px", 
                      }}>
                      Cancel
                    </Button>
                    <Button onClick={confirmDelete}  style={{
                        background: "#0a60bd",
                        color: "white",
                        marginRight: "5px", 
                      }}>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
      </div>

      <ToastContainer/>
    </div>
  );
}

export default CostCenter;
