import React from 'react'

function Login() {
  return (
    <div>
      <h1>Login Page</h1>
      <div></div>
    </div>
  )
}

export default Login
