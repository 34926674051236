// import React, { useState } from 'react';
// import './Receiptvoucherprint.css';
// import { Button } from '@mui/material';  
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function Receiptvoucherprint() {
//   const [isPrinting, setIsPrinting] = useState(false);
//   const [isButtonsVisible, setIsButtonsVisible] = useState(true); // State to control button visibility

//   const handlePrint = () => {
//     setIsPrinting(true);
//     setIsButtonsVisible(false); // Hide buttons during the print process

//     const modalContent = document.querySelector('.receiptvoucherprint-container');
//     if (!modalContent) {
//       console.error('Printing modal content not found!');
//       return;
//     }

//     setTimeout(() => {
//       html2canvas(modalContent, {
//         scale: 2,
//         logging: true,
//         useCORS: true,
//         dpi: 300,
//         scrollX: 0,
//         scrollY: -window.scrollY,
//         x: 0,
//         y: 0
//       }).then((canvas) => {
//         const imgData = canvas.toDataURL('image/png');
        
//         const pdf = new jsPDF('p', 'mm', 'A4');
//         const imgWidth = 190;
//         const imgHeight = (canvas.height * imgWidth) / canvas.width;

//         pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

//         const pageHeight = 297;
//         const totalHeight = imgHeight + 20;

//         if (totalHeight > pageHeight) {
//           let remainingHeight = totalHeight - pageHeight;
//           let startY = pageHeight + 10;

//           while (remainingHeight > 0) {
//             pdf.addPage();
//             pdf.addImage(imgData, 'PNG', 10, startY, imgWidth, imgHeight);
//             remainingHeight -= pageHeight;
//             startY = 10;
//           }
//         }

//         const pdfBlob = pdf.output('blob');
//         const pdfUrl = URL.createObjectURL(pdfBlob);
//         const link = document.createElement('a');
//         link.href = pdfUrl;
//         link.download = 'receiptvoucher.pdf';
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);

//         setIsButtonsVisible(true); // Show buttons after the print process is finished
//         setIsPrinting(false); // Reset printing state
//       }).catch((error) => {
//         console.error('Error generating PDF:', error);
//         toast.error('Error generating PDF.');
//         setIsButtonsVisible(true); // Ensure buttons are visible in case of error
//         setIsPrinting(false); // Reset printing state in case of error
//       });
//     }, 500);
//   };

//   return (
//     <div className="receiptvoucherprint-container">
//       <div className="receiptvoucherprintheader">
//         <div className="receiptvoucherprintleft-header" >
//           <p>Phadke Bhavan, Near Hari Mandir, Dudhali, KOLHAPUR - 416012. <br/>
//           <i className="fas fa-phone" style={{ fontSize: '14px', marginRight: '5px' }}></i>
//           (0231) 25 40 211 (6 Lines)</p>
//         </div>
//         <div className="receiptvoucherprintright-header">
//           <p><strong>Receipt No:</strong> 001</p>
//           <p><strong>Date:</strong> 2024-11-15</p>
//           <p><strong>Rs:</strong> 5000.00</p>
//         </div>
//       </div>

//       <div className="receiptvoucherprintmain-content">
//         <p><b>Received with thanks from:</b> Mr. John Doe</p>
//         <p><b>the sum of Rupees:</b> Five Thousand Only</p>
//         <p><b>by:</b> Cash</p>
//         <p><b>towards:</b>Payment for products</p>
//       </div>

//       <div className="receiptvoucherprintfooter">
//         <div className="left-receiptvoucherprintfooter">
//           <p><strong>Particulars:</strong> Product Purchase</p>
//           <p><strong>No.:</strong> 001</p>
//           <p><strong>Dated:</strong> 2024-11-15</p>
//           <p>Receipt valid subject to Encashment of Cheque.</p>
//         </div>
//         <div className="right-receiptvoucherprintfooter">
//           <p><strong>For Phadke Book House</strong></p>
//         </div>
//       </div>

//       {isButtonsVisible && (
//         <div className="receiptvoucherprint-btn-container">
//           <Button 
//             onClick={handlePrint}
//             className="receiptvoucherprint-button"
//             style={{
//               background: "#0a60bd",
//               color: "white",
//               marginRight: "5px",
//             }}
//           >
//             Print
//           </Button>
//           <Button 
//             className="receiptvouchercancel-button"
//             style={{ 
//               background: "red",
//               color: "white",
//             }}
//           >
//             Cancel
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Receiptvoucherprint;



import React, { useState } from 'react';
import './Receiptvoucherprint.css';
import { Button } from '@mui/material';  
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Receiptvoucherprint() {
  const [isPrinting, setIsPrinting] = useState(false);
  const [isButtonsVisible, setIsButtonsVisible] = useState(true);

  const handlePrint = () => {
    setIsPrinting(true);
    setIsButtonsVisible(false);

    const modalContent = document.querySelector('.receiptvoucherprint-container');
    if (!modalContent) {
      console.error('Printing modal content not found!');
      return;
    }

    setTimeout(() => {
      html2canvas(modalContent, {
        scale: 2,
        logging: true,
        useCORS: true,
        dpi: 300,
        scrollX: 0,
        scrollY: -window.scrollY,
        x: 0,
        y: 0
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        
        const pdf = new jsPDF('p', 'mm', 'A4');
        const imgWidth = 190;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

        const pageHeight = 297;
        const totalHeight = imgHeight + 20;

        if (totalHeight > pageHeight) {
          let remainingHeight = totalHeight - pageHeight;
          let startY = pageHeight + 10;

          while (remainingHeight > 0) {
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 10, startY, imgWidth, imgHeight);
            remainingHeight -= pageHeight;
            startY = 10;
          }
        }

        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'receiptvoucher.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setIsButtonsVisible(true);
        setIsPrinting(false);
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        toast.error('Error generating PDF.');
        setIsButtonsVisible(true);
        setIsPrinting(false);
      });
    }, 500);
  };

  return (
    <div className="receiptvoucherprint-container">
      <div className="receiptvoucherprintheader">
        <div className="receiptvoucherprintleft-header">
          <p>Phadke Bhavan, Near Hari Mandir, Dudhali, KOLHAPUR - 416012. <br/>
          <i className="fas fa-phone" style={{ fontSize: '14px', marginRight: '5px' }}></i>
          (0231) 25 40 211 (6 Lines)</p>
        </div>
        <div className="receiptvoucherprintright-header">
          <p><strong>Receipt No:</strong> 001</p>
          <p><strong>Date:</strong> 2024-11-15</p>
          <p><strong>Rs:</strong> 5000.00</p>
        </div>
      </div>

      <div className="receiptvoucherprintmain-content">
        <p><strong>Received with thanks from:</strong> Mr. John Doe</p>
        <p><strong>the sum of Rupees:</strong> Five Thousand Only</p>
        <p><strong>by:</strong> Cash</p>
        <p><strong>towards:</strong> Payment for products</p>
      </div>

      <div className="receiptvoucherprintfooter">
        <div className="left-receiptvoucherprintfooter">
          <p><strong>Particulars:</strong> Product Purchase</p>
          <p><strong>No.:</strong> 001</p>
          <p><strong>Dated:</strong> 2024-11-15</p>
          <p>Receipt valid subject to Encashment of Cheque.</p>
        </div>
        <div className="right-receiptvoucherprintfooter">
          <p><strong>For Phadke Book House</strong></p>
        </div>
      </div>

      {isButtonsVisible && (
        <div className="receiptvoucherprint-btn-container">
          <Button 
            onClick={handlePrint}
            className="receiptvoucherprint-button"
            style={{
              background: "#0a60bd",
              color: "white",
              marginRight: "5px",
            }}            disabled={isPrinting}
          >
            {isPrinting ? 'Generating PDF...' : 'Print'}
          </Button>
          <Button 
            className="receiptvouchercancel-button"
            style={{ 
              background: "red",
              color: "white",
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
}

export default Receiptvoucherprint;
