




import React, { useState, useEffect, useMemo , useRef} from "react";
import axios from "axios";
import "./TitlePress.css";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Modal, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiDeleteBin5Line } from "react-icons/ri";


function TitlePress() {
  
  const [TitlePressName, setTitlePressName] = useState('');
  const [titlepresses, setTitlepresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [id, setId] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors ] = useState('')
  
   const [deleteId, setDeleteId] = useState(null);

   // Confirmation Dialog state
       const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
       const [deleteIndex, setDeleteIndex] = useState(null);
   
  const titlepressnameRef = useRef(null);
       const saveRef = useRef(null);
       
         const handleKeyDown = (e, nextFieldRef) => {
           if (e.key === 'Enter') {
             e.preventDefault();
             if (nextFieldRef && nextFieldRef.current) {
               nextFieldRef.current.focus();
             }
           }
         };
       
  useEffect(()=>{
    fetchTitlepresses();
   },[])


  const fetchTitlepresses = async () => {
    try {
      const response = await axios.get("https://publication.microtechsolutions.co.in/php/TitlePressget.php");
      
      setTitlepresses(response.data);
    } catch (error) {
      toast.error("Error fetching title presses:", error);
    }
  };
 
 
 

  
  const resetForm = () => {
    setTitlePressName("");
   
    setIsModalOpen(false);
  };

  const handleNewClick = () => {
    resetForm();
    setIsModalOpen(true);
    setEditingIndex(-1);
    setIsEditing(false);
  };



  const handleEdit = (row) => {
    const title = titlepresses[row.index];
    setTitlePressName(title.TitlePressName);
    setEditingIndex(row.index);
    setId(title.Id);
    setIsEditing(true);
    setIsModalOpen(true);
  };
  


  const handleDelete = (index, Id) => {
      setDeleteIndex(index);
      setDeleteId(Id)
      setIsDeleteDialogOpen(true); // Show confirmation dialog
    };


    const confirmDelete = () => {
     const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        
                        const urlencoded = new URLSearchParams();
                        urlencoded.append("Id", deleteId);
                        
                        const requestOptions = {
                          method: "POST",
                          headers: myHeaders,
                          body: urlencoded,
                          redirect: "follow"
                        };
                        
                        fetch("https://publication.microtechsolutions.co.in/php/Titlepressdelete.php", requestOptions)
                          .then((response) => response.text())
                          .then((result) => console.log(result))
                          .catch((error) => console.error(error));
                          toast.success('Title Press Deleted Successfully')
                          setIsDeleteDialogOpen(false);
                          fetchTitlepresses()
    };

  
    const cancelDelete = () => {
      setIsDeleteDialogOpen(false);
      setDeleteIndex(null);
    };


  const validateForm = ()=> {
    let formErrors = {};
    let isValid = true;

    
    if (!TitlePressName) {
        formErrors.TitlePressName = "Title press Name is required.";
        isValid = false;
    
    }

    
    setErrors(formErrors);
    return isValid;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
   if (!validateForm()) return;

    const data = {
      TitlePressName : TitlePressName
    };
  
    const url = isEditing
      ? "https://publication.microtechsolutions.co.in/php/TitlePressupdate.php"
      : "https://publication.microtechsolutions.co.in/php/TitlePresspost.php";
  
    if (isEditing) {
      data.Id = id;
    }
  
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
  
      if (isEditing) {
        toast.success('Title Press updated successfully!');
      } else {
        toast.success('Title Press  added successfully!');
      }
      setIsModalOpen(false);
      resetForm();
      fetchTitlepresses(); // Refresh the list after submit
    } catch (error) {
      // console.error("Error saving record:", error);
      toast.error('Error saving record!');
    }
  };
  

 

  


  

  const columns = useMemo(
    () => [

      {
        accessorKey: 'SrNo',
        header: "Sr.No",
        size: 50,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "TitlePressName",
        header: "Title Press",
        size: 50,
      },
      
      
      {
        accessorKey: "actions",
        header: "Actions",
        size: 150,
        Cell: ({ row }) => (
          <div>
             <Button
              onClick={() => handleEdit(row)}
              style={{
                background: "#0a60bd",
                color: "white",
                marginRight: "5px", 
              }}>Edit
              {/* <CiEdit style={{color: '#FFF', fontSize:'22px', fontWeight:700}}  /> */}
            </Button>
            <Button
              onClick={() => handleDelete(row.index, row.original.Id)}
              style={{
                background: "red",
                color: "white",fontSize:'22px'
              }}>
              <RiDeleteBin5Line/>
            </Button>
          </div>
        ),
      },
    ],
    [titlepresses]
  );

  

  const table = useMaterialReactTable({
    columns,
    data: titlepresses,muiTableHeadCellProps: {
      style: {
       backgroundColor: "#E9ECEF", // Replace with your desired color
        color: "black",fontSize: '16px'
      },
    },
  });

  return (
    <div className="titlepress-container">
      <h1>Title Press Master</h1>
      <div className="titlepresstable-master">
        <div className="titlepresstable1-master">
          <Button
            onClick={handleNewClick}
            style={{ color: "#FFFF", 
              fontWeight: "700", background:'#0a60bd', width:'15%' }}
          >
            New
          </Button>
         
          <div className="titlepresstable-container">
            <MaterialReactTable table={table} />
          </div>
        </div>

        {isModalOpen && <div className="titlepress-overlay" onClick={() => setIsModalOpen(false)} />}


        <Modal open={isModalOpen} >
          <div className="titlepress-modal">
            <h1
            style={{
              textAlign: "center",
              fontWeight: "620",
              margin: "2px",fontSize:"27px"
              }}
            >
              {editingIndex >= 0 ? "Edit Title Press " : "Add Title Press "}
            </h1>

           

            <form className="titlepress-form">
                <div>
                  <label className="titlepress-label"> Title Press <b className="required">*</b></label>
                  <div>
                   

                    <input
                    type="text" 
                    id="TitlePressName" name="TitlePressName" value={TitlePressName} 
                    onChange={(e)=> setTitlePressName(e.target.value)} 
                    maxLength={100}
                    ref={titlepressnameRef} 
                    onKeyDown={(e) => handleKeyDown(e, saveRef)} 
                    className="titlepress-control" 
                    placeholder="Enter TItle Press Name"
                    />         
                    <div>
                    {errors.TitlePressName && <b className="error-text">{errors.TitlePressName}</b>}
                      </div>        

                  </div>
                </div>
              


</form>


              <div className="titlepress-btn-container">
                <Button
                  type="submit"
                  ref={saveRef} 
                  // onKeyDown={(e) => handleKeyDown(e, accgroupnameRef)} 
                  style={{
                    background: "#0a60bd",
                    color: "white",
                  }}
                  onClick={handleSubmit}
                >
                                  {editingIndex >= 0 ? "Update" : "Save"}

                </Button>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    background: "red",
                    color: "white",
                  }}
                >
                  Cancel
                </Button>
              </div>
          </div>
        </Modal>

          {/* Confirmation Dialog for Delete */}
                        <Dialog
                          open={isDeleteDialogOpen}
                          onClose={cancelDelete}
                        >
                          <DialogTitle style={{color:'navy', fontWeight:'600'}}>Confirm Deletion</DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this <b style={{color:'red'}}><u>Title Press</u></b>?
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={cancelDelete}  style={{
                                background: "red",
                                color: "white",
                                marginRight: "5px", 
                              }}>
                              Cancel
                            </Button>
                            <Button onClick={confirmDelete}  style={{
                                background: "#0a60bd",
                                color: "white",
                                marginRight: "5px", 
                              }}>
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
      </div>
      <ToastContainer />

    </div>
  );
}

export default TitlePress;
