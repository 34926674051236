

import { FaChartLine, FaUserTie, FaUser, FaBell, FaTachometerAlt , FaGraduationCap,FaExchangeAlt,FaPrint,FaCog ,
  FaCrown ,FaBuilding ,FaBook,FaFolder ,FaBoxes ,FaCalendarDay,FaFileAlt ,FaInfoCircle ,
  FaDollarSign,FaClipboardList,FaChartBar,FaUsers ,FaListUl , FaMapMarkerAlt 
,FaUniversity,FaIndustry,FaMoneyBillAlt,FaBookReader,FaFilePdf,FaWarehouse,FaNewspaper ,FaTimes,FaStar,FaUserGraduate,FaPenNib ,FaPercentage,FaUserFriends ,FaFolderOpen ,FaTasks,FaFlag,FaMoneyCheckAlt,            
FaReceipt,FaCreditCard,FaLock,FaHistory,FaSignOutAlt  ,FaHandshake,FaDatabase,FaCalculator, FaUserShield ,FaCodeBranch,FaCompressAlt,FaRecycle ,FaFileInvoice,FaArrowRight,FaMerge,FaBalanceScale ,FaFileSignature,FaShoppingCart,FaBoxOpen} from "react-icons/fa";
import { MdDashboard, MdHome } from "react-icons/md";
import PersonIcon from "@mui/icons-material/Person"; // Material-UI Person Icon

import PrintIcon from '@mui/icons-material/Print';

export const menuItems = [

  {
    title: "Home",
    icon: <MdHome />,
    
    path: "/home",
  },
  {
    title: "Dashboard",
    icon: <MdDashboard />,
    
    path: "/dashboard",
  },

  {
    title: "Masters",
    path: "/masters",
    icon: <FaGraduationCap />,

    submenus: [
      {
        title: "Account Master",
        path: "/masters/accounts",
        icon: <FaUsers  />,
      },
      {
        title: "Account Group Master",
        path: "/masters/accountgroup",
        icon: <FaUsers  />,
      },
      {
        title: "Book Master",
        path: "/masters/book",
        icon: <FaBook />,
      },
      {
        title: "Book Group Master",
        path: "/masters/bookgroup",
        icon: <FaBook />,
      },
      {
        title: "Book Medium Master",
        path: "/masters/bookmedium",
        icon: <FaBook />,
      },

      {
        title: "Standard Master",
        path: "/masters/standard",
        icon: <FaStar />,
      },
      {
        title: "College Master",
        path: "/masters/college",
        icon: <FaUniversity />,
      },
      {
        title: "College Group Master",
        path: "/masters/collegegroup",
        icon: <FaUniversity />,
      },
      {
        title: "University Master",
        path: "/masters/university",
        icon: <FaUniversity />,
      },
     

      {
        title: "Commission Master",
        path: "/masters/commission",
        icon: <FaPercentage  />,
      },

      {
        title: "Discount Master",
        path: "/masters/discount",
        icon: <FaPercentage  />,
      },
      {
        title: "Assign Canvassor Master",
        path: "/masters/assignconvassor",
        icon: <FaTasks  />,
      },
      {
        title: "User Master",
        path: "/masters/user",
        icon: <FaUser />,
      },
      {
        title: "Branch Master",
        path: "/masters/branch",
        icon: <FaUser />,
      },{
        title: "Level Master",
        path: "/masters/level",
        icon: <FaUser />,
      },
      {
        title: "Country Master",
        path: "/masters/country",
        icon: <FaFlag />,
      },
      {
        title: "State Master",
        path: "/masters/state",
        icon: <FaFlag />,
      },

      {
        title: 'City Master',
        path:'/masters/city',
        icon:<FaFlag/>,
      },

      {
        title: 'Area Master',
        path:'/masters/area',
        icon:<FaFlag/>,
      },
      {
        title: "TDS Master",
        path: "/masters/tds",
        icon: <FaMoneyCheckAlt />,
      },
      {
        title: "Depositer Group Master",
        path: "/masters/depositorgroup",
        icon: <FaUserFriends  />,
      },
      {
        title: "Prof Category Master",
        path: "/masters/profcategory",
        icon: <FaFolderOpen />,
      },
      {
        title: "Professor Master",
        path: "/masters/professors",
        icon: <FaUserGraduate />,
      },
      // {
      //   title: "Professors",
      //   path: "/masters/professors/proflist",
      //   icon: <FaUserGraduate />,
      // },
    
      {
        title: 'Employee Master',
        path: '/masters/employee',
        icon: <FaUser />
      },
      {
        title: 'Employee Group Master',
        path: '/masters/employeegroup',
        icon: <FaUsers />
      },
      {
        title: "Publication Master",
        path: "/masters/publication",
        icon: <FaBook/>
      },
      {
        title: "Author Master",
        path: "/masters/authors",
        icon: <FaPenNib />,
      },
      {
        title: "Godown Master",
        path: "/masters/godown",
        icon: <FaWarehouse />,
      },
      
      {
        title: "Title Press Master",
        path: "/masters/titlepress",
        icon: <FaNewspaper />,
      },
      {
        title: "Press Master",
        path: "/masters/press",
        icon: <FaNewspaper />,
      },
      {
        title: "Paper Size Master",
        path: "/masters/papersize",
        icon: <FaFilePdf />,
      },
      {
        title: "Sub Account Group Master",
        path: "/masters/subaccountgroup",
        icon: <FaUserFriends />,
      },

      {
        title: "Dispatch Mode Master",
        path: "/masters/dispatchmode",
        icon: <FaUserFriends />,
      },


      {
        title: "Cost Center Master",
        path: "/masters/costcenter",
        icon: <FaUserFriends />,
      },
      // {
      //   title: "Cancel",
      //   path: "/masters/cancel",
      //   icon: <FaTimes  />,
      // },
    ],
  },

  {
    title: "Transactions",
    path: "/transactions",
    icon: <FaExchangeAlt  />,

    submenus: [
      {
        title: "Receipt Voucher",
        path: "/transaction/receiptvoucher",
        icon: <FaReceipt />,
      },
      {
        title: "Payment Voucher",
        path: "/transaction/paymentvoucher",
        icon: <FaCreditCard />,
      },
      {
        title: "JV",
        path: "/transaction/jv",
        icon: <FaHandshake />,
      },
      {
        title: "Debit Note",
        path: "/transaction/debitnote",
        icon: <FaFileInvoice />,
      },
      {
        title: "Credit Note",
        path: "/transaction/creditnote",
        icon: <FaClipboardList />,
      },
      {
        title: "Bank Reconciliation",
        path: "/transaction/bankreconciliation",
        icon: <FaBalanceScale  />,
      },
      {
        title: "Sells Challan",
        path: "/transaction/saleschallan",
        icon: <FaFileSignature />,
      },
      {
        title: "Sells Invoice",
        path: "/transaction/salesinvoice",
        icon: <FaFileSignature />,
      },
      {
        title: "Sells Return",
        path: "/transaction/salesreturn-creditnote",
        icon: <FaExchangeAlt  />,
      },
      {
        title: "Purchase Return",
        path: "/transaction/purchasereturn-debitnote",
        icon: <FaExchangeAlt />,
      },

     
      {
        title: "Book purchase",
        path: "/transaction/bookpurchase",
        icon: <FaShoppingCart />,
      },
      {
        title: "Invert Challan",
        path: "/transaction/invertchallan",
        icon: <FaBoxOpen />,
      },
      {
        title: "TDS Entry",
        path: "/transaction/tds",
        icon: <FaMoneyCheckAlt />,
      },
      {
        title: "Canvassor Details",
        path: "/transaction/convassordetails",
        icon: <FaInfoCircle />,
      },
      {
        title: "Paper Out for Book Printing",
        path: "/transaction/paperforbookprinting",
        icon: <FaPrint />,
      },
      {
        title: "MisPrint",
        path: "/transaction/misprint",
        icon: <FaPrint />,
      },
      {
        title: "Sells For Canvassor",
        path: "/transaction/salestoconvassor",
        icon: <FaArrowRight />,
      },
      {
        title: "Merge Book",
        path: "/transaction/mergebook",
        icon: <FaCompressAlt  />,
      },
      {
        title: "Book Printing order to Press",
        path: "/transaction/bookprintingordertopress",
        icon: <FaPrint />,
      },
      {
        title: "Canvassor daily Report",
        path: "/transaction/convassordailyreport",
        icon: <FaFileAlt />,
      },

      {
        title: "Raddi Sales",
        path: "/transaction/raddisales",
        icon: <FaRecycle />,
      },
      // {
      //   title: "Cancel",
      //   path: "/transaction/cancel",
      //   icon: <FaTimes  />,
      // },
    ],
  },

  {
    title: "Printing",
    path: "/printing",
    icon: <FaPrint  />,

    submenus: [
      {
        title: "Ledger",
        path: "/printing/ledger",
        icon: <FaBook  />,
      },
      {
        title: "All Documents",
        path: "/printing/alldocuments",
        icon: <FaFolder  />,
      },
      {
        title: "Books of Accounts",
        path: "/printing/booksofaccounts",
        icon: <FaBook />,
      },
      {
        title: "Final Reports",
        path: "/printing/finalreports",
        icon: <FaFileAlt  />,
      },
      {
        title: "Stock",
        path: "/printing/stock",
        icon: <FaBoxes  />,

        submenus: [
          {
            title: "Stock Day Book",
            path: "/printing/stock/stockdaybook",
            icon: <FaCalendarDay  />,
          },
          {
            title: "Stock Book",
            path: "/printing/stock/stockbook",
            icon: <FaBook />,
          },
          {
            title: "Stock Statement",
            path: "/printing/stock/stockstatement",
            icon: <FaFileAlt  />,
          },
          {
            title: "Stock Statement Details",
            path: "/printing/stock/stockstmtdetails",
            icon: <FaInfoCircle  />,
          },
          {
            title: "Net sale",
            path: "/printing/stock/netsale",
            icon: <FaDollarSign  />,
          },
          {
            title: "Net sale Summary",
            path: "/printing/stock/netsalesummary",
            icon: <FaClipboardList  />,
          },
          {
            title: "Book Purchase Repo",
            path: "/printing/stock/bookpurchaserepo",
            icon: <FaBook />,
          },
        ],
      },
      {
        title: "MIS Reports",
        path: "/printing/misreports",
        icon: <FaChartBar  />,
      },
      {
        title: "MISC Reports",
        path: "/printing/miscreports",
        icon: <FaFileAlt />,

        submenus: [
          {
            title: "Account Groups",
            path: "/printing/miscreports/accountgroups",
            icon: <FaUsers  />,
          },
          {
            title: "Book Listing",
            path: "/printing/miscreports/booklisting",
            icon: <FaBook />,
          },
          {
            title: "FBT Listing",
            path: "/printing/miscreports/fbtlisting",
            icon: <FaClipboardList  />,
          },
        ],
      },

      {
        title: "Listing",
        path: "/printing/listing",
        icon: <FaListUl  />,

        submenus: [
          {
            title: "Area",
            path: "/printing/listing/area",
            icon: <FaMapMarkerAlt  />,
          },
          {
            title: "Convassors party Listing",
            path: "/printing/listing/convassorspartylisting",
            icon: <FaIndustry  />,
          },
          {
            title: "Party listing",
            path: "/printing/listing/partylisting",
            icon: <FaUser />,
          },
          {
            title: "Convassing College List",
            path: "/printing/listing/convassingcollegelist",
            icon: <FaUniversity />,
          },
        ],
      },

      {
        title: "Display Ledger",
        path: "/printing/displayledger",
        icon: <FaBook />,
      },
      {
        title: "Ageing Analysis-convassor",
        path: "/printing/ageinganalysis-convassor",
        icon: <FaChartLine />,
      },
      {
        title: "Ageing Analysis-Amount wise",
        path: "/printing/ageinganalysis-amountwise",
        icon: <FaMoneyBillAlt  />,
      },
      {
        title: "Convassing Reports",
        path: "/printing/convassingreports",
        icon: <FaChartBar />,
      },
      {
        title: "Book Printing Order",
        path: "/printing/bookprintingorder",
        icon: <FaBookReader  />,
      },
      // {
      //   title: "Cancel",
      //   path: "/printing/cancel",
      //   icon: <FaTimes  />,
      // },
    ],
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <FaCog  />,

    submenus: [
      {
        title: "Company Master",
        path: "/settings/companymaster",
        icon: <FaBuilding />,
      },
      {
        title: "User Rights",
        path: "/settings/userrights",
        icon: <FaUserShield  />,
      },
      {
        title: "Split Data",
        path: "/settings/splitdata",
        icon: <FaCodeBranch />,
      },
      {
        title: "Backup-Current Company",
        path: "/settings/backup-currentcompany",
        icon: <FaDatabase />,
      },
      {
        title: "Interest Calculation",
        path: "/settings/interestcalculation",
        icon: <FaCalculator />,
      },
      {
        title: "Lock Data",
        path: "/settings/lockdata",
        icon: <FaLock />,
      },
      {
        title: "Restore Data",
        path: "/settings/restoredata",
        icon: <FaHistory />,
      },
      // {
      //   title: "Cancel",
      //   path: "/settings/cancel",
      //   icon: <FaTimes  />,
      // },
    ],
  },
  {
    title: "Royalty",
    icon: <FaCrown  />,
    path: "/royalty",

    submenus: [
      {
        title: "Royalty Letter",
        path: "/royalty/royaltyletter",
        icon: <FaBuilding />,
      },
      {
        title: "Royalty Voucher",
        path: "/royalty/royaltyvoucher",
        icon: <FaUserShield  />,
      },
      {
        title: "Royalty Statement",
        path: "/royalty/royaltystmt",
        icon: <FaCodeBranch />,
      },
      {
        title: "Royalty Statement Authorwise",
        path: "/royalty/royaltystmtauthorwise",
        icon: <FaDatabase />,
      },
      {
        title: "Royalty Statement Summary",
        path: "/royalty/royaltystmtsummary",
        icon: <FaCalculator />,
      },
      {
        title: "Royalty Calculation",
        path: "/royalty/royaltycalc",
        icon: <FaLock />,
      },]
  },
  



];







