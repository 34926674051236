import React from 'react'

function RoyaltyStatement() {
  return (
    <div>
      RoyaltyStatement
      RoyaltyStatement
    </div>
  )
}

export default RoyaltyStatement
