import React from 'react'

function RoyaltyCalculation() {
  return (
    <div>
      RoyaltyCalculation
      RoyaltyCalculation
    </div>
  )
}

export default RoyaltyCalculation
